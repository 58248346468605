import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '@dostavkee/react-hooks'
import {
    Button,
    IconChevronDownSmall,
    IconChevronUpSmall,
    IconExit,
    IconPersonOutline,
    Truncate,
} from '@dostavkee/web-ui'
import { useNavigate } from '@tanstack/react-router'

import { useAuth } from '../../model/hooks/use-auth'
import { LogoutModal } from '../logout-modal'
import styles from './header-controls.module.scss'

export const HeaderControls = () => {
    const { profile } = useAuth()
    const navigate = useNavigate()

    const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false)
    const [isOpenControls, setIsOpenControls] = useState(false)

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => setIsOpenControls(false))

    return (
        <>
            <div className={styles['header-controls']}>
                <Truncate length={40}>{profile?.user?.company?.name}</Truncate>
                <div
                    aria-label='Открыть меню'
                    className={styles['header-controls__button']}
                    role='button'
                    tabIndex={0}
                    onClick={() => setIsOpenControls(true)}
                >
                    <Button
                        prefixEl={<IconPersonOutline size={20} />}
                        size='xs'
                        variant='secondary'
                        onClick={() => setIsOpenControls(true)}
                    />
                    {isOpenControls ? (
                        <IconChevronUpSmall size={16} />
                    ) : (
                        <IconChevronDownSmall size={16} />
                    )}
                </div>
                {isOpenControls && (
                    <div ref={ref} className={styles['header-controls__list']}>
                        <div className={styles['header-controls__list-company-name']}>
                            <Truncate length={60}>{profile?.user?.company?.legal_name}</Truncate>
                        </div>
                        <div className={styles['header-controls__list-buttons']}>
                            <Button
                                prefixEl={<IconPersonOutline size={20} />}
                                size='xs'
                                variant='ghost'
                                onClick={() => {
                                    navigate({
                                        to: location.pathname.startsWith('/dostavkee')
                                            ? '/dostavkee/profile'
                                            : '/corpdrivee/profile',
                                    })
                                    setIsOpenControls(false)
                                }}
                            >
                                Профиль
                            </Button>
                            <Button
                                prefixEl={<IconExit size={20} />}
                                size='xs'
                                variant='ghost'
                                onClick={() => {
                                    setIsOpenLogoutModal(true)
                                    setIsOpenControls(false)
                                }}
                            >
                                Выйти
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <LogoutModal isOpen={isOpenLogoutModal} onClose={() => setIsOpenLogoutModal(false)} />
        </>
    )
}
