import * as Sentry from '@sentry/react'
import { Register } from '@tanstack/react-router'

const DEFAULT_SENTRY_TRACES_SAMPLE_RATE = 0.1

/**
 * Get the Sentry traces sample rate from the global configuration.
 *
 * If the global configuration does not specify a sample rate, the default
 * sample rate is used.
 *
 */
const getSentryTracesSampleRate = () => {
    /**
     * If the global configuration does not specify a sample rate, use the default sample rate
     */
    if (!__GLOBAL__.VITE_SENTRY_TRACES_SAMPLE_RATE) {
        return DEFAULT_SENTRY_TRACES_SAMPLE_RATE
    }

    /**
     * If the global configuration specifies a sample rate as a number, return it
     */
    if (typeof __GLOBAL__.VITE_SENTRY_TRACES_SAMPLE_RATE === 'number') {
        return __GLOBAL__.VITE_SENTRY_TRACES_SAMPLE_RATE
    }

    /**
     * If the global configuration specifies a sample rate as a string, parse it as a number
     */
    if (typeof __GLOBAL__.VITE_SENTRY_TRACES_SAMPLE_RATE === 'string') {
        return Number.parseFloat(__GLOBAL__.VITE_SENTRY_TRACES_SAMPLE_RATE)
    }

    /**
     * If the global configuration specifies an invalid sample rate, use the default sample rate
     */
    return DEFAULT_SENTRY_TRACES_SAMPLE_RATE
}

/**
 * Initializes the Sentry SDK.
 */
export const initSentry = (dsn: string, router: Register['router']) => {
    Sentry.init({
        /**
         * Set the DSN for Sentry
         */
        dsn: dsn,

        /**
         * Set the release version for Sentry
         */
        release: `dostavkee-web-console@${APP_VERSION}`,

        /**
         * Set the integrations for Sentry
         */
        integrations: [
            /**
             * Enable browser tracing
             */
            Sentry.browserTracingIntegration(),

            /**
             * Enable TanStack router browser tracing
             */
            Sentry.tanstackRouterBrowserTracingIntegration(router),
        ],

        /**
         * Performance Monitoring. This sets the sample rate from global configuration
         */
        tracesSampleRate: getSentryTracesSampleRate(),

        /**
         * Set 'tracePropagationTargets' to control for which
         * URLs distributed tracing should be enabled
         */
        tracePropagationTargets: [/^https:\/\/api\.dostavkee\.ru/],

        /**
         * Session Replay. This sets the sample rate at 10%.
         * You may want to change it to 100% while in development
         * and then sample at a lower rate in production.
         */
        replaysSessionSampleRate: 0.1,

        /**
         * If you're not already sampling the entire session,
         * change the sample rate to 100% when sampling sessions where errors occur.
         */
        replaysOnErrorSampleRate: 1,

        /**
         * Filters out events with timeout errors from being sent to Sentry.
         */
        beforeSend: (event, hint) => {
            /**
             * Check if the original exception is 'Timeout'.
             * If it is, return null to filter out the event.
             */
            const error = hint.originalException
            if (error instanceof Error && /timeout/i.test(error.message)) {
                return null
            }

            /**
             * If the original exception is not 'Timeout', return the event to send it to Sentry.
             */
            return event
        },
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i,
            /graph\.facebook\.com/i,
            /analytics\.google\.com/i,
            /googletagmanager\.com/i,
        ],
    })
}

/**
 * Lazy load Sentry Browser plugin because this is a very heavy plugin
 */
import('@sentry/browser').then((lazyLoadedSentry) => {
    Sentry.addIntegration(
        lazyLoadedSentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
        })
    )
})
