import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Leaflet, {
    divIcon,
    LatLngExpression,
    LeafletEventHandlerFnMap,
    MarkerOptions,
} from 'leaflet'
import { Marker, Tooltip } from 'react-leaflet'

import { PIN_ICON_HEIGHT, PIN_ICON_WIDTH } from '@/shell/shared/config'

import styles from './point-marker.module.scss'

export interface PointMarkerProps extends MarkerOptions {
    isLoading?: boolean
    isNotFound?: boolean
    icon_label: string
    address_name?: string
    position: LatLngExpression
    eventHandlers?: LeafletEventHandlerFnMap
}

export const PointMarker = forwardRef<Leaflet.Marker, PointMarkerProps>(
    ({ isLoading, isNotFound, icon_label, address_name, ...props }, ref) => {
        return (
            <Marker
                {...props}
                ref={ref}
                icon={divIcon({
                    className: clsx(styles['point-marker'], {
                        [styles['point-marker--loading']]: isLoading,
                        [styles['point-marker--primary']]: icon_label === 'A',
                    }),
                    html: `
                        <div class=${styles['point-marker__icon']}></div>
                        <div class=${styles['point-marker__label']}>${icon_label}</div>
                        <img class=${styles['point-marker__loader']} src="/loader.png" />
                    `,
                    iconSize: new Leaflet.Point(PIN_ICON_WIDTH, PIN_ICON_HEIGHT),
                    iconAnchor: new Leaflet.Point(
                        PIN_ICON_WIDTH - PIN_ICON_WIDTH / 2,
                        PIN_ICON_HEIGHT
                    ),
                })}
            >
                {isLoading || !address_name ? null : (
                    <Tooltip
                        permanent
                        direction='top'
                        offset={[0, -60]}
                        opacity={1}
                        className={clsx(styles['point-marker__tooltip'], {
                            [styles['point-marker__tooltip--blue']]: icon_label === 'A',
                        })}
                    >
                        <div>{address_name}</div>
                    </Tooltip>
                )}
                {isLoading || !isNotFound ? null : (
                    <Tooltip
                        permanent
                        direction='top'
                        offset={[0, -60]}
                        opacity={1}
                        className={clsx(styles['point-marker__tooltip'], {
                            [styles['point-marker__tooltip--blue']]: icon_label === 'A',
                        })}
                    >
                        <div>Адрес не найден</div>
                    </Tooltip>
                )}
            </Marker>
        )
    }
)

PointMarker.displayName = 'PointMarker'
