import { createFileRoute, redirect } from '@tanstack/react-router'

/**
 * TODO: Remove this route after launch Corpdrivee
 * @deprecated Legacy route for Dostavkee
 * @see /dostavkee/supports
 */
export const Route = createFileRoute('/(redirects)/supports')({
    beforeLoad: async () => {
        throw redirect({ to: '/dostavkee/supports' })
    },
})
