import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetUsersProfileCorpTransactions400,
    GetUsersProfileCorpTransactionsQueryParams,
    GetUsersProfileCorpTransactionsQueryResponse,
} from '../entities/GetUsersProfileCorpTransactions'

type GetUsersProfileCorpTransactionsClient = typeof client<
    GetUsersProfileCorpTransactionsQueryResponse,
    GetUsersProfileCorpTransactions400,
    never
>
type GetUsersProfileCorpTransactions = {
    data: GetUsersProfileCorpTransactionsQueryResponse
    error: GetUsersProfileCorpTransactions400
    request: never
    pathParams: never
    queryParams: GetUsersProfileCorpTransactionsQueryParams
    headerParams: never
    response: GetUsersProfileCorpTransactionsQueryResponse
    client: {
        parameters: Partial<Parameters<GetUsersProfileCorpTransactionsClient>[0]>
        return: Awaited<ReturnType<GetUsersProfileCorpTransactionsClient>>
    }
}
export const getUsersProfileCorpTransactionsQueryKey = (
    params?: GetUsersProfileCorpTransactions['queryParams']
) => [{ url: '/users/profile/corp-transactions' }, ...(params ? [params] : [])] as const
export type GetUsersProfileCorpTransactionsQueryKey = ReturnType<
    typeof getUsersProfileCorpTransactionsQueryKey
>
export function getUsersProfileCorpTransactionsQueryOptions(
    params?: GetUsersProfileCorpTransactions['queryParams'],
    options: GetUsersProfileCorpTransactions['client']['parameters'] = {}
) {
    const queryKey = getUsersProfileCorpTransactionsQueryKey(params)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetUsersProfileCorpTransactions['data'],
                GetUsersProfileCorpTransactions['error']
            >({
                method: 'get',
                url: `/users/profile/corp-transactions`,
                params,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение истории транзакций corp
 * @summary Получение истории транзакций corp
 * @link /users/profile/corp-transactions
 */
export function useGetUsersProfileCorpTransactions<
    TData = GetUsersProfileCorpTransactions['response'],
    TQueryData = GetUsersProfileCorpTransactions['response'],
    TQueryKey extends QueryKey = GetUsersProfileCorpTransactionsQueryKey,
>(
    params?: GetUsersProfileCorpTransactions['queryParams'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetUsersProfileCorpTransactions['response'],
                GetUsersProfileCorpTransactions['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetUsersProfileCorpTransactions['client']['parameters']
    } = {}
): UseQueryResult<TData, GetUsersProfileCorpTransactions['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getUsersProfileCorpTransactionsQueryKey(params)
    const query = useQuery({
        ...(getUsersProfileCorpTransactionsQueryOptions(
            params,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetUsersProfileCorpTransactions['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
