import React, { FC } from 'react'
import { Button, Logo } from '@dostavkee/web-ui'
import { Link } from '@tanstack/react-router'

import styles from './not-found.module.scss'

export interface NotFoundProps {}

export const NotFound: FC<NotFoundProps> = () => {
    return (
        <section className={styles['not-found']}>
            <div>
                <Logo />
                <h1>404</h1>
                <h2>Страница не найдена</h2>
                <Link to='/dostavkee/orders/active'>
                    <Button variant='secondary'>Вернуться на главную</Button>
                </Link>
            </div>
        </section>
    )
}

NotFound.displayName = 'NotFound'
