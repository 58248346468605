import React from 'react'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import TopBarProgress from 'react-topbar-progress-indicator'

TopBarProgress.config({
    barColors: {
        '0': '#B1F248',
        '1.0': '#0051C3',
    },
    shadowBlur: 0,
    barThickness: 3,
})

export const HeaderLoader = () => {
    const isMutating = useIsMutating()
    const isFetching = useIsFetching({
        predicate: (query) => query.state.dataUpdateCount === 0,
    })

    if (isMutating || isFetching) {
        return <TopBarProgress />
    }

    return null
}
