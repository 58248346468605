import { GetOrdersQueryParamsType, ServiceOrder } from '@dostavkee/contracts-console/entities'
import {
    getOrdersPublicIdQueryKey,
    getOrdersQueryKey,
    usePostOrdersOrderIdGive,
} from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

interface UseOrderGiveParcelProps {
    order: ServiceOrder
}

export const useOrderGiveParcel = ({ order }: UseOrderGiveParcelProps) => {
    const queryClient = useQueryClient()

    const { mutate, isPending } = usePostOrdersOrderIdGive({
        mutation: {
            onSuccess: async () => {
                /**
                 * Обновляем кэш заказа
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersPublicIdQueryKey(order.public_id.toString()),
                })

                /**
                 * Обновляем кэш списка активных заказов
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersQueryKey({ type: GetOrdersQueryParamsType.active }),
                })

                toast.success('Ваш ответ сохранен')
            },
            onError: (error) => {
                Sentry.captureException(error)

                toast.error('Ошибка сохранения ответа')
            },
        },
    })

    return {
        mutate,
        isPending,
    }
}
