import {
    GetOrders400,
    GetOrdersQueryParams,
    GetOrdersQueryResponse,
} from '@dostavkee/contracts-console/entities'
import { GetOrdersQueryKey, useGetOrders } from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseOrdersFetchProps {
    params: GetOrdersQueryParams
    query?: Partial<
        QueryObserverOptions<
            GetOrdersQueryResponse,
            GetOrders400,
            GetOrdersQueryResponse,
            GetOrdersQueryResponse,
            GetOrdersQueryKey
        >
    >
}

export const useOrdersFetch = ({ params, query }: UseOrdersFetchProps) => {
    const { data, isLoading, isPlaceholderData } = useGetOrders(params, {
        query,
    })

    return {
        orders: data?.orders,
        total: data?.total,
        isLoading,
        isPlaceholderData,
    }
}
