import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconPersonOutline: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2ZM9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7Z'
                fill={color}
                fillRule='evenodd'
            />
            <path
                d='M12.0001 13C6.99809 13 3.9797 16.9303 3.55249 21.0019C3.49486 21.5511 3.94772 22 4.5 22C5.05228 22 5.49342 21.5504 5.56358 21.0026C5.974 17.7977 8.34802 15 12.0001 15C15.6521 15 18.026 17.7977 18.4364 21.0026C18.5066 21.5504 18.9477 22 19.5 22C20.0523 22 20.5051 21.5511 20.4475 21.0019C20.0203 16.9303 17.0021 13 12.0001 13Z'
                fill={color}
            />
        </svg>
    )
}
