import React from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { Alert, IconInfoFilled } from '@dostavkee/web-ui'

import invoice from '@/assets/invoice.svg'
import { usePayment } from '@/dostavkee/entities/balance'

import styles from './payment-form-selected.module.scss'

export interface PaymentFormSelectedInvoiceProps {}

export const PaymentFormSelectedInvoice: React.FC<PaymentFormSelectedInvoiceProps> = () => {
    const { paymentInvoiceResponse, paymentFormValues } = usePayment()

    if (!paymentInvoiceResponse) {
        return null
    }

    return (
        <>
            <Alert
                icon={<IconInfoFilled color={'var(--text-and-icon-primary)'} />}
                size='small'
                style={{ marginBottom: 16 }}
                variant='warning'
            >
                Не меняйте назначение платежа, чтобы оплата прошла быстрее
            </Alert>
            {paymentFormValues?.amount && (
                <div className={styles['payment-form-selected__text']}>
                    Оплата по счёту <br />
                    <span className={styles['payment-form-selected__amount']}>
                        {moneyFormatter(paymentFormValues?.amount)}
                    </span>
                </div>
            )}
            <div className={styles['payment-form-selected']}>
                <div className={styles['payment-form-selected__invoice-img-wrapper']}>
                    <img src={invoice} />
                </div>
            </div>
        </>
    )
}
