import { GetRideOrdersQueryParamsStatuses } from '@dostavkee/contracts-console/entities'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/corpdrivee/_sidebar-layout/ride-orders')({
    onEnter: () => {
        document.title = 'CorpDrivee - Поездки'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        id?: string
        name?: string
        page?: number
        from_date?: string
        to_date?: string
        statuses?: GetRideOrdersQueryParamsStatuses[]
    } => {
        return {
            page: (search.page as number) ?? 1,
        }
    },
})
