import React, { useEffect } from 'react'
import {
    getUsersProfileBalanceQueryKey,
    useGetUsersProfilePaymentPaymentId,
} from '@dostavkee/contracts-console/hooks'
import { moneyFormatter } from '@dostavkee/helpers'
import { useQueryClient } from '@tanstack/react-query'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify'

import { usePayment } from '@/dostavkee/entities/balance'
import { PaymentStatusCode } from '@/shell/shared/config'

import styles from './payment-form-selected.module.scss'

export interface PaymentFormSelectedSBPProps {}

export const PaymentFormSelectedSBP: React.FC<PaymentFormSelectedSBPProps> = () => {
    const { paymentResponse, paymentFormValues, setPaymentStatus } = usePayment()
    const queryClient = useQueryClient()

    const { data } = useGetUsersProfilePaymentPaymentId(paymentResponse?.paymentId as string, {
        query: {
            enabled: Boolean(paymentResponse?.paymentId),
            refetchInterval: (response) => {
                if (response.state.data?.status === PaymentStatusCode.NEW) {
                    return 2000
                }

                return 0
            },
        },
    })

    useEffect(() => {
        if (data?.status === PaymentStatusCode.CONFIRMED) {
            queryClient
                .invalidateQueries({
                    queryKey: getUsersProfileBalanceQueryKey(),
                })
                .then(() => {
                    toast.success('Баланс успешно пополнен')
                    setPaymentStatus('success')
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.status])

    if (!paymentResponse) {
        return null
    }

    return (
        <>
            {paymentFormValues?.amount && (
                <div className={styles['payment-form-selected__text']}>
                    Оплата по СБП <br />
                    <span className={styles['payment-form-selected__amount']}>
                        {moneyFormatter(paymentFormValues?.amount)}
                    </span>
                </div>
            )}
            <div className={styles['payment-form-selected']}>
                <div className={styles['payment-form-selected__qr']}>
                    <div>
                        <QRCode
                            size={200}
                            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                            value={paymentResponse.paymentUrl}
                            viewBox={`0 0 200 200`}
                        />
                    </div>
                    Отсканируйте QR-код и оплатите в приложении вашего банка
                </div>
            </div>
        </>
    )
}
