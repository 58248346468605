import { getUsersProfileCorpTransactions } from '@dostavkee/contracts-console/api'
import { getUsersProfileCorpTransactionsQueryKey } from '@dostavkee/contracts-console/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'

import { PAGE_SIZE } from '@/shell/shared/config'

export const useTransactionsInfinite = () => {
    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: getUsersProfileCorpTransactionsQueryKey(),
        queryFn: async ({ pageParam }) => {
            const response = await getUsersProfileCorpTransactions({
                limit: PAGE_SIZE,
                offset: pageParam * PAGE_SIZE,
            })

            return {
                data: response.transactions,
                page: pageParam,
            }
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage) => {
            if (!lastPage.data) {
                return
            }

            if (lastPage.data.length < PAGE_SIZE) {
                return
            }

            return lastPage.page + 1
        },
    })

    return {
        transactions: data?.pages?.flatMap((page) => page.data!).filter(Boolean),
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
        isFetchingNextPage,
        status,
        error,
    }
}
