import { createFileRoute, redirect } from '@tanstack/react-router'

/**
 * TODO: Remove this route after launch Corpdrivee
 * @deprecated Legacy route for Dostavkee
 * @see /dostavkee/addresses
 */
export const Route = createFileRoute('/(redirects)/addresses')({
    beforeLoad: async () => {
        throw redirect({ to: '/dostavkee/addresses' })
    },
})
