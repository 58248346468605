import React from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import clsx from 'clsx'
import dayjs from 'dayjs'

import styles from './transaction-item.module.scss'

export interface TransactionItemProps {
    date: string | Date
    message: string | React.ReactNode
    amount: number
    commission?: string | number
    variant?: 'payment' | 'income'
    card?: string
}

const SYMBOL_MAP = {
    payment: '-',
    income: '+',
}

export const TransactionItem: React.FC<TransactionItemProps> = ({
    date,
    message,
    amount,
    commission,
    variant = 'payment',
    card,
}) => {
    return (
        <div className={styles['transaction-item']}>
            <div>
                <div className={styles['transaction-item__subtext']}>
                    {dayjs(date).format('DD.MM.YYYY, HH:mm')}
                </div>
                <div className={styles['transaction-item__text']}>{message}</div>
                {commission && (
                    <div className={styles['transaction-item__subtext']}>
                        Агентское вознаграждение
                    </div>
                )}
            </div>
            <div>
                {card && <div className={styles['transaction-item__subtext']}>{card}</div>}
                <div
                    className={clsx([
                        styles['transaction-item__text'],
                        styles[`transaction-item__text--${variant}`],
                    ])}
                >
                    {SYMBOL_MAP[variant]}
                    {moneyFormatter(amount)}
                </div>
                {commission && (
                    <div className={styles['transaction-item__subtext']}>{commission}%</div>
                )}
            </div>
        </div>
    )
}
