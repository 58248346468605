import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconCheck: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M21.6338 5.37471C22.1221 5.87432 22.1221 6.68436 21.6338 7.18397L10.4878 18.5878C9.95065 19.1374 9.07977 19.1374 8.54262 18.5878L2.36624 12.2685C1.87792 11.10249 1.87792 10.9589 2.36624 10.4593C2.85455 9.95966 3.64627 9.95966 4.13458 10.4593L9.51521 15.9644L19.8654 5.37471C20.3537 4.8751 21.1454 4.8751 21.6338 5.37471Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
