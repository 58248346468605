import { ServiceRideOrderStatus } from '@dostavkee/contracts-console/entities'
import { ChipVariant } from '@dostavkee/web-ui'

export const RIDE_ORDER_STATUS = new Map<ServiceRideOrderStatus, string>([
    [ServiceRideOrderStatus.RideOrderStatusDriverAccepted, 'Принят'],
    [ServiceRideOrderStatus.RideOrderStatusProcessing, 'В процессе'],
    [ServiceRideOrderStatus.RideOrderStatusDone, 'Завершён'],
    [ServiceRideOrderStatus.RideOrderStatusCanceled, 'Отменён'],
])

export const RIDE_ORDER_STATUS_TO_CHIP = new Map<ServiceRideOrderStatus, ChipVariant>([
    [ServiceRideOrderStatus.RideOrderStatusDriverAccepted, ChipVariant.InfoDeep],
    [ServiceRideOrderStatus.RideOrderStatusProcessing, ChipVariant.Success],
    [ServiceRideOrderStatus.RideOrderStatusDone, ChipVariant.SuccessDeep],
    [ServiceRideOrderStatus.RideOrderStatusCanceled, ChipVariant.Error],
])
