import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostEmployeesCheck400,
    PostEmployeesCheckMutationRequest,
    PostEmployeesCheckMutationResponse,
} from '../entities/PostEmployeesCheck'

type PostEmployeesCheckClient = typeof client<
    PostEmployeesCheckMutationResponse,
    PostEmployeesCheck400,
    PostEmployeesCheckMutationRequest
>
type PostEmployeesCheck = {
    data: PostEmployeesCheckMutationResponse
    error: PostEmployeesCheck400
    request: PostEmployeesCheckMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostEmployeesCheckMutationResponse
    client: {
        parameters: Partial<Parameters<PostEmployeesCheckClient>[0]>
        return: Awaited<ReturnType<PostEmployeesCheckClient>>
    }
}
/**
 * @description Проверка номера сотрудника в Drivee
 * @summary Проверка номера сотрудника в Drivee
 * @link /employees/check
 */
export function usePostEmployeesCheck(
    options: {
        mutation?: UseMutationOptions<
            PostEmployeesCheck['response'],
            PostEmployeesCheck['error'],
            {
                data: PostEmployeesCheck['request']
            }
        >
        client?: PostEmployeesCheck['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostEmployeesCheck['data'],
                PostEmployeesCheck['error'],
                PostEmployeesCheck['request']
            >({
                method: 'post',
                url: `/employees/check`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
