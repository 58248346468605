import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

import { MODULE_NAMES } from '../../config/module-names'

const ActiveModuleStorage = createJSONStorage<MODULE_NAMES>(() => localStorage)

const ActiveModuleAtom = atomWithStorage<MODULE_NAMES>(
    '@dostavkee/active-module',
    MODULE_NAMES.dostavkee,
    ActiveModuleStorage,
    {
        getOnInit: true,
    }
)

export const useActiveModule = () => {
    const [activeModule, setActiveModule] = useAtom(ActiveModuleAtom)

    const setActiveModuleHandler = (name: MODULE_NAMES) => {
        try {
            if (!Object.values(MODULE_NAMES).includes(name)) {
                throw new Error(`Invalid module name: ${name}`)
            }

            setActiveModule(name)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    return {
        activeModule,
        setActiveModule: setActiveModuleHandler,
    }
}
