import { createFileRoute } from '@tanstack/react-router'

import { PAYMENT_STATUS } from '@/shell/shared/config'

export const Route = createFileRoute('/_dashboard/dostavkee/_sidebar-layout')({
    validateSearch: (
        search: Record<string, unknown>
    ): {
        payment_status?: PAYMENT_STATUS
    } => {
        return {
            payment_status: search?.payment_status as PAYMENT_STATUS,
        }
    },
})
