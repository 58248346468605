import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/dostavkee/_sidebar-layout/balance/')({
    onEnter: () => {
        document.title = 'Dostavkee - Баланс'
    },

    validateSearch: (
        search: Record<string, unknown>
    ): {
        page: number
    } => {
        return {
            page: (search.page as number) ?? 1,
        }
    },
})
