import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostUsersAuthRefresh400,
    PostUsersAuthRefreshMutationResponse,
} from '../entities/PostUsersAuthRefresh'

type PostUsersAuthRefreshClient = typeof client<
    PostUsersAuthRefreshMutationResponse,
    PostUsersAuthRefresh400,
    never
>
type PostUsersAuthRefresh = {
    data: PostUsersAuthRefreshMutationResponse
    error: PostUsersAuthRefresh400
    request: never
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostUsersAuthRefreshMutationResponse
    client: {
        parameters: Partial<Parameters<PostUsersAuthRefreshClient>[0]>
        return: Awaited<ReturnType<PostUsersAuthRefreshClient>>
    }
}
/**
 * @description User refresh
 * @summary Refresh
 * @link /users/auth/refresh
 */
export function usePostUsersAuthRefresh(
    options: {
        mutation?: UseMutationOptions<
            PostUsersAuthRefresh['response'],
            PostUsersAuthRefresh['error']
        >
        client?: PostUsersAuthRefresh['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({}) => {
            const res = await client<
                PostUsersAuthRefresh['data'],
                PostUsersAuthRefresh['error'],
                void
            >({
                method: 'post',
                url: `/users/auth/refresh`,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
