import React, { FC } from 'react'
import { ServiceOrder } from '@dostavkee/contracts-console/entities'
import { Alert, Button, IconRepeat, IconWarningFilled } from '@dostavkee/web-ui'
import { Link, useNavigate } from '@tanstack/react-router'
import clsx from 'clsx'

import { useOrderArchive } from '../../api/mutations/use-order-archive'
import styles from './order-alert.module.scss'

export interface OrderAlertCourierCanceledProps {
    order: ServiceOrder
    fullWidth?: boolean
}

export const OrderAlertCourierCanceled: FC<OrderAlertCourierCanceledProps> = ({
    order,
    fullWidth,
}) => {
    const navigate = useNavigate()

    const { mutate, isPending } = useOrderArchive({ order })

    return (
        <Alert
            key={order.id}
            icon={<IconWarningFilled size={24} />}
            size='small'
            variant='error'
            className={clsx([
                styles['order-alert'],
                {
                    [styles['order-alert--full-width']]: fullWidth,
                },
            ])}
        >
            <div className={styles['order-alert__content']}>
                <Link
                    params={{ public_id: order.public_id.toString() }}
                    to='/dostavkee/orders/$public_id'
                >
                    Заказ №{order.public_id}
                </Link>{' '}
                <div>Курьер отменил заказ</div>
            </div>
            <div className={styles['order-alert__controls']}>
                <Button
                    color='white'
                    prefixEl={<IconRepeat size={16} />}
                    size='xs'
                    variant='secondary'
                    onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        navigate({
                            to: '/dostavkee/orders/create',
                            search: { public_id: order.public_id.toString() },
                        })
                    }}
                >
                    Повторить
                </Button>
                <Button
                    isLoading={isPending}
                    size='xs'
                    variant='outline'
                    onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        mutate({ orderId: order.id })
                    }}
                >
                    В архив
                </Button>
            </div>
        </Alert>
    )
}
