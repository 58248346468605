import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import ReactDOM from 'react-dom/client'

import '@dostavkee/web-ui/dostavkee-variables.scss'
import '@dostavkee/web-ui/corpdrivee-variables.scss'
import 'react-toastify/dist/ReactToastify.css'
import '@/shell/shared/styles/global.scss'

import { initSentry } from './init-sentry'
import { routeTree } from './route-tree.generated'

const SHOULD_RESPECT_SENTRY =
    typeof __GLOBAL__.VITE_SENTRY_DSN === 'string' &&
    __GLOBAL__.VITE_SENTRY_DSN.length > 0 &&
    __GLOBAL__.VITE_SENTRY_DSN.includes('https') &&
    __GLOBAL__.VITE_SENTRY_ENABLED === 'true'

/**
 * Checks if the error is a ModuleNotFoundError.
 *
 * This function checks if the error has a message property and if that message contains the string
 * "Failed to fetch dynamically imported module".
 *
 * @param {any} error - The error object to check.
 * @return {boolean} Returns `true` if the error is a ModuleNotFoundError, `false` otherwise.
 */
const isModuleNotFoundError = (error: any): boolean => {
    // Check if the error has a message property and if that message contains the string
    // "Failed to fetch dynamically imported module".
    return (
        typeof error?.message === 'string' &&
        /Failed to fetch dynamically imported module/.test(error.message)
    )
}

/**
 * A component that renders an error message when an error occurs.
 */
export const ErrorComponent = ({ error }: { error: any }): JSX.Element => {
    // Filter out other errors that are not ModuleNotFoundErrors.
    // Do not show the error if the error is a ModuleNotFoundError.
    if (isModuleNotFoundError(error)) {
        return <div />
    }

    return (
        <div>
            <p>Something went wrong!</p>
        </div>
    )
}

// Create a new router instance
const router = createRouter({ routeTree, defaultErrorComponent: ErrorComponent })

const enableMocking = async () => {
    if (import.meta.env.PROD || __GLOBAL__.VITE_MOCK_ENABLED !== 'true') {
        return
    }

    try {
        /**
         * Import the worker and start it
         */
        const { worker } = await import('./mocks')

        // Null pointer reference
        if (!worker) {
            throw new Error('Worker not found')
        }

        // Start the worker
        const result = worker.start()

        // Unhandled exception
        if (!result) {
            throw new Error('Worker.start() returned null or undefined')
        }

        return result
    } catch (error) {
        console.error('Failed to enable mocking', error)
    }
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

const queryClient = new QueryClient()

enableMocking().then(() => {
    /**
     * Init sentry
     */
    if (SHOULD_RESPECT_SENTRY) {
        initSentry(__GLOBAL__.VITE_SENTRY_DSN!, router)
    }

    /**
     * Render the app
     */
    const rootElement = document.querySelector('#root')!

    if (!rootElement.innerHTML) {
        const root = ReactDOM.createRoot(rootElement)
        root.render(
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        )
    }

    /**
     * When a new deployment occurs, the hosting service may delete the assets from previous deployments.
     * As a result, a user who visited your site before the new deployment might encounter an import error.
     * This error happens because the assets running on that user's device are outdated and it tries
     * to import the corresponding old chunk, which is deleted. This event is useful for addressing this situation.
     *
     * https://vitejs.dev/guide/build.html#load-error-handling
     */
    window.addEventListener('vite:preloadError', () => {
        window.location.reload()
    })
})
