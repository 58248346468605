import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './tapbar.module.scss'

export interface TapbarProps {
    children?: React.ReactNode
    variant?: 'modern' | 'transparent'
}

export const Tapbar: FC<TapbarProps> = ({ children, variant = 'modern' }) => {
    return <div className={clsx(styles['tapbar'], styles[`tapbar--${variant}`])}>{children}</div>
}

Tapbar.displayName = 'Tapbar'
