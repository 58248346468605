import React from 'react'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { useActiveModule } from '@/shell/shared/model'

export const Route = createFileRoute('/_dashboard/')({
    component: RootGuard,
})

function RootGuard() {
    const { activeModule } = useActiveModule()

    return <Navigate to={`/${activeModule}`} />
}
