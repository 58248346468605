import type { ResponseConfig } from '../../axios-client'
import client from '../../axios-client'
import type {
    GetUsersProfileCorpTransactionsQueryParams,
    GetUsersProfileCorpTransactionsQueryResponse,
} from '../entities/GetUsersProfileCorpTransactions'

/**
 * @description Получение истории транзакций corp
 * @summary Получение истории транзакций corp
 * @link /users/profile/corp-transactions
 */
export async function getUsersProfileCorpTransactions(
    params?: GetUsersProfileCorpTransactionsQueryParams,
    options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<GetUsersProfileCorpTransactionsQueryResponse>['data']> {
    const res = await client<GetUsersProfileCorpTransactionsQueryResponse>({
        method: 'get',
        url: `/users/profile/corp-transactions`,
        params,
        ...options,
    })

    return res.data
}
