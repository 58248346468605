/**
 * Названия туров
 */
export enum TOUR_NAME {
    /**
     * Начальные туры для новых пользователей dostavkee
     */
    TOUR_DOSTAVKEE_BASE = 'TOUR_DOSTAVKEE_BASE',

    /**
     * Начальные туры для новых пользователей corpdrivee
     */
    TOUR_CORPDRIVEE_BASE = 'TOUR_CORPDRIVEE_BASE',

    /**
     * Тур только для нового модуля corpdrivee
     */
    TOUR_NEW_MODULE_CORPDRIVEE = 'TOUR_NEW_MODULE_CORPDRIVEE',

    /**
     * Тур только для нового модуля dostavkee
     */
    TOUR_NEW_MODULE_DOSTAVKEE = 'TOUR_NEW_MODULE_DOSTAVKEE',
}
