import React, { FC } from 'react'
import { ServiceCourierModel } from '@dostavkee/contracts-console/entities'
import { copyToClipboard, phoneFormatter } from '@dostavkee/helpers'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Button, Modal } from '@dostavkee/web-ui'
import { toast } from 'react-toastify'

interface CourierPhoneModalProps {
    courier: ServiceCourierModel
    onClose?: () => void
}

export const CourierPhoneModal: FC<CourierPhoneModalProps> = ({ courier, onClose }) => {
    const { isMobile } = useDeviceDetection()

    return (
        <Modal isOpen={Boolean(courier)} onClose={onClose}>
            <Modal.Header onClose={onClose}>Номер курьера</Modal.Header>
            <Modal.Body>
                <span
                    aria-label='phone-number'
                    role='button'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                        copyToClipboard(phoneFormatter(courier.contact_phone)).then(() =>
                            toast.success('Номер телефона скопирован')
                        )
                    }
                >
                    {phoneFormatter(courier.contact_phone)}
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button size='small' variant='secondary' onClick={onClose}>
                    Закрыть
                </Button>
                {isMobile ? (
                    <Button
                        size='small'
                        variant='primary'
                        onClick={() => (window.location.href = `tel:${courier.contact_phone}`)}
                    >
                        Позвонить
                    </Button>
                ) : (
                    <Button
                        size='small'
                        variant='primary'
                        onClick={() =>
                            copyToClipboard(phoneFormatter(courier.contact_phone)).then(() =>
                                toast.success('Номер телефона скопирован')
                            )
                        }
                    >
                        Скопировать
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}
