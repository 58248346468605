import { createFileRoute, redirect } from '@tanstack/react-router'

/**
 * TODO: Remove this route after launch Corpdrivee
 * @deprecated Legacy route for Dostavkee
 * @see /dostavkee/documents
 */
export const Route = createFileRoute('/(redirects)/documents')({
    beforeLoad: async () => {
        throw redirect({ to: '/dostavkee/documents' })
    },
})
