import React, { useEffect, useMemo } from 'react'
import { Alert, Button, IconInfoFilled, InputMoney, RadioGroup } from '@dostavkee/web-ui'
import { Controller, useForm } from 'react-hook-form'

import { PAYMENT_METHOD } from '@/shell/shared/config'
import { useAnalytics, useAuth } from '@/shell/shared/model'

import styles from './payment-form-select.module.scss'
import { PaymentFormSelectPriceVariants } from './payment-form-select-price-variants'

const PAYMENT_MIN_AMOUNT = __GLOBAL__.VITE_PAYMENT_MIN_AMOUNT ?? 1000

export interface PaymentFormSelectValues {
    amount: number
    paymentMethod: PAYMENT_METHOD
}

export interface PaymentFormSelectProps {
    onSubmit: (values: PaymentFormSelectValues) => void
    initialValues?: PaymentFormSelectValues
}

export const PaymentFormSelect: React.FC<PaymentFormSelectProps> = ({
    onSubmit,
    initialValues,
}) => {
    const analytics = useAnalytics()
    const { company } = useAuth()

    const isVerifiedCompany = useMemo(() => {
        if (company?.need_verification) {
            return company?.verified
        }

        return true
    }, [company?.need_verification, company?.verified])

    const {
        handleSubmit,
        control,
        watch,
        formState: { isSubmitting },
    } = useForm<PaymentFormSelectValues>({
        defaultValues: initialValues ?? {
            amount: undefined,
            paymentMethod: isVerifiedCompany ? 'sbp' : 'invoice',
        },
    })

    useEffect(() => {
        analytics.track('b2b_balance_view')
    }, [analytics])

    const paymentMethod = watch('paymentMethod')

    return (
        <form className={styles['payment-form-select']} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['payment-form-select__fields-container']}>
                <Controller
                    control={control}
                    name='amount'
                    render={({ field, fieldState }) => (
                        <div>
                            <InputMoney
                                {...field}
                                errorMessage={fieldState.error?.message}
                                id='amount'
                                isInvalid={fieldState.invalid}
                                label='Сумма пополнения'
                            />
                            <PaymentFormSelectPriceVariants
                                value={field.value}
                                onChange={field.onChange}
                            />
                        </div>
                    )}
                    rules={{
                        required: 'Обязательное поле',
                        min: {
                            value: PAYMENT_MIN_AMOUNT,
                            message: `Мин. сумма пополнения: ${PAYMENT_MIN_AMOUNT} ₽`,
                        },
                    }}
                />

                <Controller
                    control={control}
                    name='paymentMethod'
                    render={({ field }) => (
                        <RadioGroup label='Способ оплаты'>
                            {!isVerifiedCompany && (
                                <Alert
                                    icon={<IconInfoFilled size={20} />}
                                    size='small'
                                    style={{ marginBottom: 16 }}
                                    variant='warning'
                                >
                                    Пополните баланс по счёту, чтобы подтвердить организацию
                                </Alert>
                            )}
                            <RadioGroup.Button
                                {...field}
                                checked={field.value === 'sbp'}
                                disabled={!isVerifiedCompany}
                                label='Система быстрых платежей (СБП)'
                                value='sbp'
                            />
                            <RadioGroup.Button
                                {...field}
                                checked={field.value === 'card'}
                                disabled={!isVerifiedCompany}
                                label='Банковской картой'
                                value='card'
                            />
                            <RadioGroup.Button
                                {...field}
                                checked={field.value === 'invoice'}
                                label='По счёту'
                                value='invoice'
                            />
                        </RadioGroup>
                    )}
                />
            </div>
            <div
                className={styles['payment-form-select__buttons-container']}
                onClick={() => {
                    if (paymentMethod === 'card') {
                        analytics.track('b2b_balance_card_send')
                    }
                    if (paymentMethod === 'sbp') {
                        analytics.track('b2b_balance_sbp_send')
                    }
                    if (paymentMethod === 'invoice') {
                        analytics.track('b2b_balance_check_send')
                    }
                }}
            >
                <Button isLoading={isSubmitting} size='small' type='submit'>
                    {paymentMethod === 'sbp' && 'Пополнить через СБП'}
                    {paymentMethod === 'card' && 'Пополнить картой'}
                    {paymentMethod === 'invoice' && 'Сформировать счёт'}
                </Button>
            </div>
        </form>
    )
}
