import React from 'react'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { useAuth } from '@/shell/shared/model'

export const Route = createFileRoute('/_dashboard/corpdrivee/')({
    component: CorpdriveeGuard,
})

function CorpdriveeGuard() {
    const { isCorpdriveeEnabled } = useAuth()

    if (!isCorpdriveeEnabled) {
        return <Navigate to='/corpdrivee/city-not-available' />
    }

    return <Navigate to='/corpdrivee/ride-orders' />
}
