import React, { memo } from 'react'
import { Logo, LogoCorpdrivee } from '@dostavkee/web-ui'
import { Link, useLocation } from '@tanstack/react-router'

import { ModuleSwitcher } from '../module-switcher'
import styles from './header.module.scss'
import { HeaderControls } from './header-controls'
import { HeaderLoader } from './header-loader'

export const Header = memo(() => {
    const location = useLocation()

    return (
        <>
            <header className={styles['header']}>
                <div className={styles['header__logos-container']}>
                    {location.pathname.startsWith('/dostavkee') && (
                        <Link
                            className={styles['header__logo-item']}
                            id='dostavkee-link'
                            to='/dostavkee'
                        >
                            <Logo width={163} />
                        </Link>
                    )}
                    {location.pathname.startsWith('/corpdrivee') && (
                        <Link
                            className={styles['header__logo-item']}
                            id='corpdrivee-link'
                            to='/corpdrivee'
                        >
                            <LogoCorpdrivee width={163} />
                        </Link>
                    )}
                    <ModuleSwitcher />
                </div>
                <div className={styles['header__controls']}>
                    <HeaderControls />
                </div>
            </header>
            <HeaderLoader />
        </>
    )
})

Header.displayName = 'Header'
