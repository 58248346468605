import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetUsersProfilePaymentPaymentId400,
    GetUsersProfilePaymentPaymentIdPathParams,
    GetUsersProfilePaymentPaymentIdQueryResponse,
} from '../entities/GetUsersProfilePaymentPaymentId'

type GetUsersProfilePaymentPaymentIdClient = typeof client<
    GetUsersProfilePaymentPaymentIdQueryResponse,
    GetUsersProfilePaymentPaymentId400,
    never
>
type GetUsersProfilePaymentPaymentId = {
    data: GetUsersProfilePaymentPaymentIdQueryResponse
    error: GetUsersProfilePaymentPaymentId400
    request: never
    pathParams: GetUsersProfilePaymentPaymentIdPathParams
    queryParams: never
    headerParams: never
    response: GetUsersProfilePaymentPaymentIdQueryResponse
    client: {
        parameters: Partial<Parameters<GetUsersProfilePaymentPaymentIdClient>[0]>
        return: Awaited<ReturnType<GetUsersProfilePaymentPaymentIdClient>>
    }
}
export const getUsersProfilePaymentPaymentIdQueryKey = (
    paymentId: GetUsersProfilePaymentPaymentIdPathParams['payment_id']
) => [{ url: '/users/profile/payment/:payment_id', params: { paymentId: paymentId } }] as const
export type GetUsersProfilePaymentPaymentIdQueryKey = ReturnType<
    typeof getUsersProfilePaymentPaymentIdQueryKey
>
export function getUsersProfilePaymentPaymentIdQueryOptions(
    paymentId: GetUsersProfilePaymentPaymentIdPathParams['payment_id'],
    options: GetUsersProfilePaymentPaymentId['client']['parameters'] = {}
) {
    const queryKey = getUsersProfilePaymentPaymentIdQueryKey(paymentId)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetUsersProfilePaymentPaymentId['data'],
                GetUsersProfilePaymentPaymentId['error']
            >({
                method: 'get',
                url: `/users/profile/payment/${paymentId}`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение статуса платежа
 * @summary Получение статуса платежа
 * @link /users/profile/payment/:payment_id
 */
export function useGetUsersProfilePaymentPaymentId<
    TData = GetUsersProfilePaymentPaymentId['response'],
    TQueryData = GetUsersProfilePaymentPaymentId['response'],
    TQueryKey extends QueryKey = GetUsersProfilePaymentPaymentIdQueryKey,
>(
    paymentId: GetUsersProfilePaymentPaymentIdPathParams['payment_id'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetUsersProfilePaymentPaymentId['response'],
                GetUsersProfilePaymentPaymentId['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetUsersProfilePaymentPaymentId['client']['parameters']
    } = {}
): UseQueryResult<TData, GetUsersProfilePaymentPaymentId['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getUsersProfilePaymentPaymentIdQueryKey(paymentId)
    const query = useQuery({
        ...(getUsersProfilePaymentPaymentIdQueryOptions(
            paymentId,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetUsersProfilePaymentPaymentId['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
