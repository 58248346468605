import React, { FC, useRef } from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { Button } from '@dostavkee/web-ui'

import styles from './payment-form-select-price-variants.module.scss'

const PAYMENT_PRICE_VARIANTS = [1000, 1500, 2000, 2500, 3000, 4000, 5000]

interface PaymentFormSelectPriceVariantsProps {
    onChange: (price: number) => void
    value: number
}

export const PaymentFormSelectPriceVariants: FC<PaymentFormSelectPriceVariantsProps> = ({
    onChange,
    value,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    return (
        <div className={styles['price-variants-container']}>
            <div ref={ref} className={styles['price-variants']}>
                {PAYMENT_PRICE_VARIANTS.map((price) => (
                    <Button
                        key={price}
                        isRounded
                        color={price === value ? 'black' : undefined}
                        size='xs'
                        variant='secondary'
                        onClick={(event) => {
                            ref.current?.scrollTo({
                                left: event.currentTarget.offsetLeft,
                                behavior: 'smooth',
                            })
                            onChange(price)
                        }}
                    >
                        {moneyFormatter(price)}
                    </Button>
                ))}
            </div>
        </div>
    )
}
