import React, { useRef } from 'react'
import { DomainCorpTransactionSourceString } from '@dostavkee/contracts-console/entities'
import { Button, IconArrowDown, TransactionItem } from '@dostavkee/web-ui'
import { useVirtualizer } from '@tanstack/react-virtual'

import { useTransactionsInfinite } from '@/corpdrivee/entities/balance'

import { BalanceTransactionsListLoader } from './balance-transactions-list.loader'
import styles from './balance-transactions-list.module.scss'

const TRANSACTION_MESSAGE = new Map([
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringIncome, 'Пополнение баланса'],
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringCharge, 'Оплата заказа'],
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringOrderRefund, 'Возврат средств'],
])

export const BalanceTransactionsListInvoice = () => {
    const { transactions, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
        useTransactionsInfinite()

    const parentRef = useRef<HTMLDivElement>(null)

    const transactionsLength = transactions?.length ?? 0

    const virtualizer = useVirtualizer({
        count: hasNextPage ? transactionsLength + 1 : transactionsLength,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 73,
        overscan: 6,
        lanes: 1,
    })

    const items = virtualizer.getVirtualItems()

    if (isLoading) {
        return <BalanceTransactionsListLoader />
    }

    if (transactions?.length === 0) {
        return (
            <div className={styles['balance-transaction-list__empty']}>
                Здесь будет история транзакций
            </div>
        )
    }

    return (
        <section ref={parentRef} className={styles['balance-transaction-list']}>
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                    maxWidth: '635px',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${items[0]?.start ?? 0}px)`,
                    }}
                >
                    {items.map((virtualItem) => {
                        const isLoaderRow = virtualItem.index > transactionsLength - 1
                        const transaction = transactions?.[virtualItem.index]!

                        return (
                            <div
                                key={virtualItem.key}
                                ref={virtualizer.measureElement}
                                data-index={virtualItem.index}
                                style={{
                                    borderTop:
                                        isLoaderRow || virtualItem.index === 0
                                            ? 'none'
                                            : '1px solid var(--border-primary)',
                                }}
                            >
                                {!isLoaderRow && (
                                    <TransactionItem
                                        amount={transaction.amount}
                                        commission={transaction.commission_percent}
                                        date={transaction.date}
                                        message={TRANSACTION_MESSAGE.get(transaction.source)}
                                        variant={
                                            transaction.source ===
                                            DomainCorpTransactionSourceString.CorpTransactionSourceStringIncome
                                                ? 'income'
                                                : 'payment'
                                        }
                                    />
                                )}
                                {hasNextPage && isLoaderRow && (
                                    <div className={styles['balance-transaction-list__more']}>
                                        <Button
                                            isText
                                            fullWidth={false}
                                            isLoading={isFetchingNextPage}
                                            variant='ghost'
                                            postfixEl={
                                                <IconArrowDown
                                                    color={'var(--text-and-icon-accent)'}
                                                    size={24}
                                                />
                                            }
                                            onClick={() => fetchNextPage()}
                                        >
                                            <a href='#' onClick={(event) => event.preventDefault()}>
                                                Показать ещё
                                            </a>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
