import React from 'react'
import { useRouterState } from '@tanstack/react-router'

export const ModuleThemeSwitcher = () => {
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })

    const isCorpdrivee = pathname.startsWith('/corpdrivee')
    const hasCorpdriveeClass = document.documentElement.classList.contains('corpdrivee')

    React.useEffect(() => {
        if (isCorpdrivee && !hasCorpdriveeClass) {
            document.documentElement.classList.add('corpdrivee')
        } else if (!isCorpdrivee && hasCorpdriveeClass) {
            document.documentElement.classList.remove('corpdrivee')
        }
    }, [hasCorpdriveeClass, isCorpdrivee])

    return null
}
