import React from 'react'

import { IconSvgProps } from './icon'

export const IconFilter: React.FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M17 12C19.2091 12 21 13.7909 21 16C21 18.2091 19.2091 20 17 20C15.1364 20 13.5705 18.7256 13.1262 17.0008L3.99995 17.0001C3.44768 17 3 16.5523 3 16C3 15.4477 3.44774 15 4.00005 15L13.126 15.0002C13.5699 13.2749 15.1361 12 17 12ZM17 14C15.8954 14 15 14.8954 15 16C15 17.1046 15.8954 18 17 18C18.1046 18 19 17.1046 19 16C19 14.8954 18.1046 14 17 14ZM7 4C8.86393 4 10.4301 5.27489 10.874 7.00024L20 7.00002C20.5523 7.00001 21 7.44774 21 8.00005C21 8.55232 20.5523 9.00003 20 9.00008L10.8738 9.00076C10.4295 10.7256 8.86357 12 7 12C4.79086 12 3 10.2091 3 8C3 5.79086 4.79086 4 7 4ZM7 6C5.89543 6 5 6.89543 5 8C5 9.10457 5.89543 10 7 10C8.10457 10 9 9.10457 9 8C9 6.89543 8.10457 6 7 6Z'
                fill={color}
            />
        </svg>
    )
}
