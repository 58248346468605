import React, { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './logo-corpdrivee.module.scss'

export interface LogoCorpdriveeProps extends HTMLAttributes<HTMLDivElement> {
    width?: number
    color?: string
    className?: string
}

export const LogoCorpdrivee: FC<LogoCorpdriveeProps> = ({
    width = 200,
    className = '',
    color = '#F57011',
    ...props
}) => {
    return (
        <div className={clsx(styles.logo, className)} {...props}>
            <svg
                fill='none'
                height='41'
                viewBox='0 0 164 41'
                width={width}
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M8.56852 29.4573C3.46894 29.4573 0.371643 26.2351 0.371643 21.0669C0.371643 15.9943 3.62537 12.6764 8.72495 12.6764C13.105 12.6764 15.8268 15.101 16.4525 18.9932H11.8535C11.4781 17.3661 10.3831 16.3452 8.66238 16.3452C6.34723 16.3452 4.8768 18.2913 4.8768 21.0669C4.8768 23.8743 6.34723 25.7885 8.66238 25.7885C10.3831 25.7885 11.5407 24.7357 11.8535 23.0767H16.4838C15.8581 27.0008 12.9485 29.4573 8.56852 29.4573Z'
                    fill='#323942'
                />
                <path
                    d='M26.4667 29.4573C21.3358 29.4573 17.9882 26.1075 17.9882 21.0669C17.9882 16.0262 21.3358 12.6764 26.4667 12.6764C31.5976 12.6764 34.9451 16.0262 34.9451 21.0669C34.9451 26.1394 31.5976 29.4573 26.4667 29.4573ZM26.4667 25.7885C28.9383 25.7885 30.4713 23.8424 30.4713 21.0669C30.4713 18.2913 28.9383 16.3452 26.4667 16.3452C23.9951 16.3452 22.4934 18.2913 22.4934 21.0669C22.4934 23.8424 23.9951 25.7885 26.4667 25.7885Z'
                    fill='#323942'
                />
                <path
                    d='M46.2193 12.8678H46.9701V16.9514H45.2181C42.5901 16.9514 41.589 18.7379 41.589 21.2264V29.2659H37.1777V12.8678H41.1823L41.589 15.3243C42.465 13.8568 43.7164 12.8678 46.2193 12.8678Z'
                    fill='#323942'
                />
                <path
                    d='M75.5619 12.4871C77.9192 12.4871 80.1163 13.6788 80.7942 15.1924V6.85364H85.4136V29.0033H80.7942V26.8787C79.9553 28.3915 77.8239 29.4219 75.5619 29.4219C71.1701 29.4219 67.5532 25.6878 67.5532 20.9545C67.5532 16.2211 71.1701 12.4871 75.5619 12.4871ZM72.1388 20.9545C72.1388 23.3043 74.0114 25.3012 76.3375 25.3012C78.6637 25.3012 80.5362 23.3052 80.5362 20.9545C80.5362 18.6038 78.6957 16.6077 76.3375 16.6077C73.9793 16.6077 72.1388 18.5727 72.1388 20.9545Z'
                    fill='#323942'
                />
                <path
                    d='M97.8587 12.6175V17.4147C97.438 17.318 96.8251 17.2533 96.4701 17.2533C93.8531 17.2533 92.2057 18.7661 92.2057 21.1487V29.0043H87.588V12.9074H92.2057V15.9338H92.2385C92.8523 13.8075 94.5318 12.488 96.6303 12.488C97.0822 12.488 97.5038 12.5208 97.8587 12.6175Z'
                    fill='#323942'
                />
                <path
                    d='M99.447 6.56482H104.065V11.104H99.447V6.56482ZM104.065 12.9076V29.0045H99.447V12.9076H104.065Z'
                    fill='#323942'
                />
                <path
                    d='M104.972 12.9076H109.721L112.66 23.5635H112.724L115.664 12.9076H120.41L115.502 29.0045H109.882L104.972 12.9076Z'
                    fill='#323942'
                />
                <path
                    d='M58.1833 29.3825C55.8259 29.3825 53.6288 28.1908 52.9509 26.6772V35.016H48.3315V12.8663H52.9509V14.9909C53.7898 13.4781 55.9212 12.4477 58.1833 12.4477C62.575 12.4477 66.192 16.1818 66.192 20.9151C66.192 25.6485 62.575 29.3825 58.1833 29.3825ZM61.6063 20.9151C61.6063 18.5653 59.7337 16.5684 57.4076 16.5684C55.0815 16.5684 53.2089 18.5644 53.2089 20.9151C53.2089 23.2658 55.0494 25.2619 57.4076 25.2619C59.7658 25.2619 61.6063 23.2969 61.6063 20.9151Z'
                    fill='#323942'
                />
                <path
                    d='M135.26 0H151.24C157.928 0 163.358 5.40087 163.358 12.0524V27.9476C163.358 34.5991 157.928 40 151.24 40H135.26C128.572 40 123.142 34.5991 123.142 27.9476V12.0524C123.142 5.40087 128.572 0 135.26 0Z'
                    fill={color}
                />
                <path
                    d='M163.358 27.9476V24.1258C161.061 26.2498 158.244 27.7869 155.497 28.2411C152.94 28.6603 150.973 28.3389 149.484 27.5354C152.526 26.0891 155.202 24.2236 157.078 22.1345C160.695 18.145 160.955 12.8419 157.724 9.78865C154.781 6.95896 149.323 7.31529 145.831 10.69C141.891 14.3581 140.661 20.6533 142.502 25.5721C138.329 26.5712 134.192 26.4384 131.902 24.9293C134.747 23.6437 137.072 22.0646 138.561 20.269C140.858 17.5022 141.111 12.9747 138.659 10.655C135.814 7.98603 130.743 8.78953 127.898 12.297C125.896 14.7703 124.793 18.8576 125.734 22.6795C124.891 22.8961 124.02 23.0847 123.156 23.2524V27.6332C124.709 27.3467 126.226 26.9904 127.68 26.5432C130.946 30.3022 137.669 31.3991 144.841 29.31C147.328 31.9441 151.303 33.3904 156.93 32.3354C159.108 31.9231 161.236 31.0707 163.203 29.855C163.301 29.2332 163.372 28.6044 163.372 27.9546L163.358 27.9476ZM131.354 14.7424C132.583 13.2332 134.585 12.814 135.653 13.7782C136.559 14.6445 136.334 16.3494 134.972 17.9913C133.876 19.3118 132.063 20.4367 129.801 21.3659C129.415 19.0812 129.9 16.4821 131.354 14.7424ZM148.543 14.1625C150.608 12.0105 153.291 11.717 154.584 12.9397C156.136 14.386 155.778 17.1528 153.551 19.5913C151.676 21.6175 149.287 23.0987 146.632 24.1887C145.305 20.9118 146.211 16.538 148.536 14.1555L148.543 14.1625Z'
                    fill='white'
                />
            </svg>
        </div>
    )
}

LogoCorpdrivee.displayName = 'LogoCorpdrivee'
