import React from 'react'

import { IconSvgProps } from './icon'

export const IconLogoDostavkee: React.FC<IconSvgProps> = ({ size = 24 }) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 29 29'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.99294 0.136108H20.1873C24.8718 0.136108 28.6754 3.92307 28.6754 8.58695V19.7323C28.6754 24.3962 24.8718 28.1831 20.1873 28.1831H8.99294C4.30852 28.1831 0.504883 24.3962 0.504883 19.7323V8.58695C0.504883 3.92307 4.30852 0.136108 8.99294 0.136108Z'
                fill='#0050C8'
            />
            <path
                d='M28.6753 19.7322V17.0524C27.0663 18.5418 25.0931 19.6195 23.1691 19.938C21.378 20.2319 20.0003 20.0066 18.9571 19.4432C21.0877 18.4291 22.9625 17.121 24.2763 15.6562C26.8104 12.8589 26.9925 9.1405 24.729 6.99962C22.6672 5.0155 18.8439 5.26535 16.3984 7.63159C13.6379 10.2036 12.7768 14.6176 14.066 18.0665C11.1432 18.7671 8.24493 18.674 6.64081 17.6158C8.63366 16.7144 10.2624 15.6072 11.3055 14.3482C12.9146 12.4082 13.0917 9.23358 11.3744 7.6071C9.38159 5.73566 5.82891 6.29905 3.83606 8.75837C2.43369 10.4926 1.66115 13.3586 2.32051 16.0383C1.73004 16.1902 1.11988 16.3225 0.514648 16.4401V19.5118C1.6021 19.3109 2.66496 19.0611 3.68352 18.7475C5.97161 21.3832 10.6806 22.1523 15.7046 20.6875C17.4465 22.5345 20.2315 23.5486 24.173 22.8088C25.6983 22.5198 27.1893 21.9221 28.5671 21.0697C28.6359 20.6336 28.6852 20.1927 28.6852 19.7371L28.6753 19.7322ZM6.257 10.473C7.11811 9.41484 8.52048 9.1209 9.26842 9.79697C9.90317 10.4044 9.74571 11.5998 8.79112 12.7511C8.0235 13.677 6.75398 14.4658 5.16954 15.1173C4.89891 13.5153 5.23844 11.6929 6.257 10.473ZM18.2977 10.0664C19.7444 8.55751 21.6241 8.35175 22.5295 9.20908C23.6169 10.2232 23.366 12.1632 21.8061 13.873C20.4923 15.2937 18.8193 16.3323 16.9593 17.0965C16.0293 14.7989 16.6641 11.7321 18.2928 10.0615L18.2977 10.0664Z'
                fill='white'
            />
        </svg>
    )
}
