import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './balance-transactions-list.module.scss'

export const BalanceTransactionsListLoader = () => {
    return (
        <ContentLoader className={styles['balance-transaction-list__loader']}>
            <rect height='40' rx='8' ry='8' width='100%' x='0' y='10' />
            <rect height='60' rx='8' ry='8' width='100%' x='0' y='60' />
            <rect height='60' rx='8' ry='8' width='100%' x='0' y='130' />
        </ContentLoader>
    )
}
