import React, { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './logo.module.scss'

export interface LogoProps extends HTMLAttributes<HTMLDivElement> {
    width?: number
    color?: string
    className?: string
}

export const Logo: FC<LogoProps> = ({
    width = 200,
    className = '',
    color = '#0050C8',
    ...props
}) => {
    return (
        <div className={clsx(styles.logo, className)} {...props}>
            <svg
                fill='none'
                height='41'
                viewBox='0 0 164 41'
                width={width}
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M134.232 0.046875H150.197C156.878 0.046875 162.303 5.44775 162.303 12.0993V27.9945C162.303 34.646 156.878 40.0469 150.197 40.0469H134.232C127.551 40.0469 122.127 34.646 122.127 27.9945V12.0993C122.127 5.44775 127.551 0.046875 134.232 0.046875Z'
                    fill={color}
                />
                <path
                    d='M162.303 27.7087V23.8869C160.008 26.0109 157.194 27.548 154.45 28.0022C151.896 28.4214 149.931 28.1 148.443 27.2965C151.482 25.8502 154.155 23.9847 156.029 21.8956C159.643 17.9061 159.903 12.603 156.675 9.54976C153.734 6.72007 148.281 7.0764 144.794 10.4511C140.857 14.1192 139.629 20.4144 141.467 25.3332C137.299 26.3323 133.165 26.1995 130.878 24.6904C133.72 23.4048 136.043 21.8257 137.53 20.0301C139.825 17.2633 140.078 12.7358 137.629 10.4161C134.787 7.74714 129.72 8.55064 126.878 12.0581C124.878 14.5314 123.776 18.6188 124.716 22.4406C123.874 22.6572 123.004 22.8458 122.141 23.0135V27.3943C123.692 27.1078 125.207 26.7515 126.66 26.3044C129.923 30.0633 136.639 31.1602 143.804 29.0712C146.288 31.7052 150.26 33.1515 155.882 32.0965C158.057 31.6843 160.183 30.8319 162.148 29.6161C162.247 28.9943 162.317 28.3655 162.317 27.7157L162.303 27.7087ZM130.33 14.5035C131.558 12.9943 133.558 12.5751 134.625 13.5393C135.53 14.4057 135.306 16.1105 133.944 17.7524C132.85 19.0729 131.039 20.1978 128.779 21.1271C128.393 18.8423 128.878 16.2432 130.33 14.5035ZM147.503 13.9236C149.566 11.7716 152.246 11.4781 153.538 12.7009C155.089 14.1471 154.731 16.914 152.506 19.3524C150.632 21.3786 148.246 22.8598 145.594 23.9498C144.267 20.6729 145.173 16.2991 147.496 13.9166L147.503 13.9236Z'
                    fill='white'
                />
                <path
                    d='M8.43361 12.2201C10.7915 12.2201 12.953 13.345 13.6618 14.917V6.58862H18.1531V28.7091H13.6618V26.5851C12.8196 28.0664 10.6231 29.1214 8.43361 29.1214C3.91423 29.1214 0.426453 25.4533 0.426453 20.6672C0.426453 15.8812 3.91423 12.2131 8.43361 12.2131M4.91776 20.6742C4.91776 23.1196 6.88972 25.076 9.33889 25.076C11.7881 25.076 13.7951 23.1126 13.7951 20.6742C13.7951 18.2358 11.7951 16.2375 9.33889 16.2375C6.8827 16.2375 4.91776 18.2288 4.91776 20.6742Z'
                    fill='#323942'
                />
                <path
                    d='M28.988 12.3389C34.2443 12.3389 37.6759 15.6926 37.6759 20.7301C37.6759 25.7677 34.2443 29.1214 28.988 29.1214C23.7318 29.1214 20.3002 25.7677 20.3002 20.7301C20.3002 15.6926 23.7318 12.3389 28.988 12.3389ZM33.0934 20.7301C33.0934 17.9563 31.5214 16.007 28.988 16.007C26.4547 16.007 24.9178 17.9563 24.9178 20.7301C24.9178 23.5039 26.4547 25.4533 28.988 25.4533C31.5214 25.4533 33.0934 23.5039 33.0934 20.7301Z'
                    fill='#323942'
                />
                <path
                    d='M46.9604 25.9003C48.8482 25.9003 50.0061 25.2924 50.0061 24.1466C50.0061 22.8051 48.8833 22.7073 46.2236 22.3579C42.3779 21.9108 39.3954 21.0514 39.3954 17.6627C39.3954 14.2741 42.2516 12.2688 46.4832 12.2968C50.9043 12.2968 53.95 14.2461 54.1746 17.5929H49.8447C49.7184 16.2863 48.4692 15.4828 46.6376 15.4828C44.806 15.4828 43.7183 16.1186 43.7183 17.1737C43.7183 18.4173 45.1288 18.5431 47.5008 18.8295C51.2833 19.2138 54.4272 20.0732 54.4272 23.7763C54.4272 27.0322 51.3465 29.1073 46.9253 29.1073C42.5042 29.1073 39.234 26.9693 39.0375 23.4549H43.3323C43.4937 24.8942 44.7428 25.8793 46.9534 25.8793L46.9604 25.9003Z'
                    fill='#323942'
                />
                <path
                    d='M58.0271 16.3284H55.3043V12.5275H58.0271V6.58862H62.5465V12.5275H66.329V16.3284H62.5465V23.5109C62.5465 24.6567 62.9956 25.1388 64.1816 25.1388H66.4273V28.9397H62.743C59.5991 28.9397 58.0271 27.3746 58.0271 24.2445V16.3284Z'
                    fill='#323942'
                />
                <path
                    d='M75.9856 12.3389C80.6032 12.3389 83.2629 14.379 83.2629 18.3057V24.1118C83.2629 24.9712 83.6138 25.1319 84.3226 25.1319H84.8349V28.9328H82.5261C80.1822 28.9328 79.4453 27.8149 79.4804 26.2498C78.3295 28.0664 76.6593 29.1214 74.0979 29.1214C70.5399 29.1214 67.9714 27.4306 67.9714 24.3004C67.9714 20.821 70.6031 18.8437 75.5365 18.8437H78.8067V18.0472C78.8067 16.5799 77.747 15.6227 75.8593 15.6227C74.0979 15.6227 72.9119 16.4192 72.7154 17.6349H68.3574C68.6802 14.4419 71.6276 12.3389 75.9856 12.3389ZM74.961 25.8725C77.333 25.8725 78.7786 24.4052 78.8067 22.1694V21.848H75.5014C73.6066 21.848 72.5189 22.6166 72.5189 23.986C72.5189 25.1319 73.4452 25.8725 74.954 25.8725H74.961Z'
                    fill='#323942'
                />
                <path
                    d='M88.9683 12.5275L92.9403 24.3353L96.8491 12.5275H101.593L95.6631 28.9327H90.049L84.1191 12.5275H88.9613H88.9683Z'
                    fill='#323942'
                />
                <path
                    d='M107.761 6.58862V19.4864L114.204 12.5275H119.298L113.368 18.9764L119.621 28.9327H114.393L110.225 22.2952L107.754 24.9432V28.9327H103.235V6.58862H107.754H107.761Z'
                    fill='#323942'
                />
            </svg>
        </div>
    )
}

Logo.displayName = 'Logo'
