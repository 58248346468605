import React from 'react'

import { IconSvgProps } from './icon'

export const IconChevronDownSmall: React.FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M17.7364 9.26363C17.3849 8.91216 16.8151 8.91216 16.4636 9.26363L12 13.7272L7.53639 9.26363C7.18492 8.91216 6.61507 8.91216 6.2636 9.26363C5.91213 9.6151 5.91213 10.185 6.2636 10.5364L11.3636 15.6364C11.7151 15.9879 12.285 15.9879 12.6364 15.6364L17.7364 10.5364C18.0879 10.1849 18.0879 9.6151 17.7364 9.26363Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
