import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconArrowDown: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 20 20'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M14.7803 7.71967C14.4874 7.42678 14.0126 7.42678 13.7197 7.71967L10 11.4393L6.28033 7.71967C5.98743 7.42678 5.51256 7.42678 5.21967 7.71967C4.92678 8.01257 4.92678 8.48744 5.21967 8.78033L9.46969 13.0303C9.76259 13.3232 10.2375 13.3232 10.5304 13.0303L14.7803 8.78033C15.0732 8.48743 15.0732 8.01256 14.7803 7.71967Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
