import React from 'react'

import styles from './radio-group.module.scss'

export interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string
    name: string
    value: string
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
    ({ label, value, name, ...props }, ref) => {
        return (
            <div className={styles['radio-button']}>
                <input
                    ref={ref}
                    aria-disabled={props.disabled}
                    className={styles['radio-button__input']}
                    {...props}
                    id={value}
                    name={name}
                    type='radio'
                    value={value}
                />
                <label className={styles['radio-button__label']} htmlFor={value}>
                    {label}
                </label>
            </div>
        )
    }
)

RadioButton.displayName = 'RadioButton'
