/* eslint-disable unicorn/no-useless-undefined */
import { useCallback } from 'react'
import {
    ServiceCreateInvoiceResponse,
    V1TopUpBalanceResponse,
} from '@dostavkee/contracts-console/entities'
import {
    usePostUsersProfileBalance,
    usePostUsersProfileInvoice,
} from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import { atom, useAtom } from 'jotai'
import { toast } from 'react-toastify'

import { PAYMENT_STATUS } from '@/shell/shared/config'

import { PaymentFormSelectValues } from '../../../../shared/ui/payment-form/payment-form-select/payment-form-select'

const isPaymentModalOpenAtom = atom(false)

const paymentFormValuesAtom = atom<(PaymentFormSelectValues & { requested: boolean }) | undefined>(
    undefined
)

const paymentResponseAtom = atom<V1TopUpBalanceResponse | undefined>(undefined)

const paymentInvoiceResponseAtom = atom<ServiceCreateInvoiceResponse | undefined>(undefined)

const paymentStatusAtom = atom<PAYMENT_STATUS | undefined>(undefined)

export const usePayment = () => {
    const navigate = useNavigate()
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })

    const [paymentFormValues, setPaymentFormValues] = useAtom(paymentFormValuesAtom)

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useAtom(isPaymentModalOpenAtom)

    const [paymentStatus, setPaymentStatus] = useAtom(paymentStatusAtom)

    const [paymentResponse, setPaymentResponse] = useAtom(paymentResponseAtom)

    const [paymentInvoiceResponse, setPaymentInvoiceResponse] = useAtom(paymentInvoiceResponseAtom)

    const { mutateAsync: postUsersProfileBalance } = usePostUsersProfileBalance()

    const { mutateAsync: postUsersProfileInvoice } = usePostUsersProfileInvoice()

    const handleSubmitPayment = useCallback(
        async (data: PaymentFormSelectValues) => {
            if (data.paymentMethod === 'card' || data.paymentMethod === 'sbp') {
                const toastId = toast.loading('Генерируем ссылку на пополнение')

                return postUsersProfileBalance(
                    {
                        data: {
                            amount: data.amount,
                            type: data.paymentMethod,
                            path: pathname,
                        },
                    },
                    {
                        onSuccess: (response) => {
                            toast.success('Оплатите в приложении банка')

                            setPaymentResponse(response)
                            setPaymentFormValues({
                                ...data,
                                requested: true,
                            })
                        },
                        onError: (error) => {
                            toast.error(
                                `Ошибка при пополнении баланса. Код ошибки ${error?.error_code}`
                            )

                            Sentry.captureException(error)
                        },
                        onSettled: () => {
                            toast.dismiss(toastId)
                        },
                    }
                )
            }
            if (data.paymentMethod === 'invoice') {
                const toastId = toast.loading('Формируем счёт на оплату')

                return postUsersProfileInvoice(
                    {
                        data: {
                            amount: data.amount,
                        },
                    },
                    {
                        onSuccess: (response) => {
                            toast.success('Счёт на оплату успешно создан')

                            setPaymentInvoiceResponse(response)
                            setPaymentFormValues({
                                ...data,
                                requested: true,
                            })
                        },
                        onError: (error) => {
                            toast.error(
                                `Ошибка при формировании счёта. Код ошибки ${error?.error_code}`
                            )

                            Sentry.captureException(error)
                        },
                        onSettled: () => {
                            toast.dismiss(toastId)
                        },
                    }
                )
            }
        },
        [
            pathname,
            postUsersProfileBalance,
            postUsersProfileInvoice,
            setPaymentFormValues,
            setPaymentInvoiceResponse,
            setPaymentResponse,
        ]
    )

    return {
        handleSubmitPayment,
        isPaymentModalOpen,
        paymentInvoiceResponse,
        paymentResponse,
        paymentFormValues,
        paymentStatus,
        setPaymentStatus,
        resetPaymentFormStates: () => {
            if (paymentResponse) {
                setPaymentResponse(undefined)
            }
            if (paymentInvoiceResponse) {
                setPaymentInvoiceResponse(undefined)
            }
            if (paymentStatus) {
                navigate({ search: { payment_status: undefined } })
                setPaymentStatus(undefined)
            }
            setPaymentFormValues((values) => {
                if (!values) {
                    return undefined
                }

                return {
                    ...values,
                    requested: false,
                }
            })
        },
        setPaymentFormValues,
        handleOpenPaymentModal: () => setIsPaymentModalOpen(true),
        handleClosePaymentModal: () => {
            if (paymentResponse) {
                setPaymentResponse(undefined)
            }
            if (paymentInvoiceResponse) {
                setPaymentInvoiceResponse(undefined)
            }
            if (paymentFormValues) {
                setPaymentFormValues(undefined)
            }
            if (paymentStatus) {
                navigate({ search: { payment_status: undefined } })
                setPaymentStatus(undefined)
            }
            setIsPaymentModalOpen(false)
        },
    }
}
