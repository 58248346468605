import React from 'react'

import { PAYMENT_STATUS } from '@/shell/shared/config'

import { PaymentFormStatusFailure } from './payment-form-status-failure'
import { PaymentFormStatusSuccess } from './payment-form-status-success'

export interface PaymentFormStatusProps {
    paymentStatus?: PAYMENT_STATUS
}

export const PaymentFormStatus: React.FC<PaymentFormStatusProps> = ({ paymentStatus }) => {
    if (!paymentStatus) {
        return null
    }

    return (
        <>
            {paymentStatus === 'success' && <PaymentFormStatusSuccess />}
            {paymentStatus === 'fail' && <PaymentFormStatusFailure />}
        </>
    )
}
