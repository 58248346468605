import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './alert.module.scss'

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    variant?: 'info' | 'success' | 'warning' | 'error'
    size?: 'small' | 'medium' | 'large'
    icon?: ReactNode
}

export const Alert: FC<AlertProps> = ({
    size = 'medium',
    children,
    variant = 'info',
    icon,
    className,
    ...props
}) => {
    return (
        <div
            className={clsx(styles['alert'], className, {
                [styles[`alert--variant-${variant}`]]: true,
                [styles[`alert--size-${size}`]]: true,
            })}
            {...props}
        >
            {icon && <div className={styles['alert__icon']}>{icon}</div>}
            <span>{children}</span>
        </div>
    )
}

Alert.displayName = 'Alert'
