import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

import { TOUR_NAME } from '@/shell/shared/config'

import { useAuth } from './use-auth'

const tourStateStorage = createJSONStorage<TOUR_NAME[]>(() => localStorage)

const shownToursAtom = (key: string) =>
    atomWithStorage<TOUR_NAME[]>(key, [], tourStateStorage, {
        getOnInit: true,
    })

/**
 * Хук который запоминает какие туры были показаны пользователю
 * Предназначен для того, чтобы не показывать один tour несколько раз
 * Если название тура было сохранено в localStorage, то tour для него не будет показан
 */
export const useShownTours = () => {
    const { profile } = useAuth()
    const [shownTours, setShownTours] = useAtom(
        useMemo(
            () => shownToursAtom(`@dostavkee/shown-tours-${profile?.user?.id}`),
            [profile?.user?.id]
        )
    )

    return { shownTours, setShownTours }
}
