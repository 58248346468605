import React, { FC } from 'react'

import styles from './title.module.scss'

export interface TitleProps {
    children: React.ReactNode
    variant?: 'h1' | 'h2'
}

export const Title: FC<TitleProps> = ({ children, variant = 'h1' }) => {
    const className = styles[variant]
    const Component = variant

    return <Component className={className}>{children}</Component>
}
