import React from 'react'

import { IconSvgProps } from './icon'

export const IconLogoCorpdrivee: React.FC<IconSvgProps> = ({ size = 24 }) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 29 29'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.48806 0H19.6824C24.3669 0 28.1705 3.78696 28.1705 8.45084V19.5962C28.1705 24.26 24.3669 28.047 19.6824 28.047H8.48806C3.80363 28.047 0 24.26 0 19.5962V8.45084C0 3.78696 3.80363 0 8.48806 0Z'
                fill='#FF6B34'
            />
            <path
                d='M28.1704 19.5962V16.9165C26.5614 18.4058 24.5882 19.4836 22.6643 19.802C20.8732 20.0959 19.4954 19.8706 18.4522 19.3072C20.5828 18.2931 22.4576 16.985 23.7714 15.5202C26.3055 12.7229 26.4876 9.00451 24.2241 6.86363C22.1624 4.87952 18.339 5.12937 15.8935 7.49561C13.133 10.0676 12.2719 14.4816 13.5611 17.9306C10.6383 18.6311 7.74005 18.538 6.13593 17.4798C8.12878 16.5784 9.7575 15.4712 10.8007 14.2122C12.4097 12.2722 12.5868 9.09759 10.8696 7.47111C8.87671 5.59968 5.32403 6.16307 3.33118 8.62238C1.9288 10.3566 1.15627 13.2226 1.81563 15.9024C1.22516 16.0542 0.615001 16.1865 0.00976562 16.3041V19.3758C1.09722 19.1749 2.16007 18.9251 3.17864 18.6115C5.46673 21.2472 10.1757 22.0164 15.1997 20.5515C16.9416 22.3985 19.7267 23.4126 23.6681 22.6728C25.1935 22.3838 26.6844 21.7861 28.0622 20.9337C28.1311 20.4977 28.1803 20.0567 28.1803 19.6011L28.1704 19.5962ZM5.75212 10.337C6.61323 9.27886 8.0156 8.98491 8.76353 9.66098C9.39829 10.2685 9.24083 11.4638 8.28623 12.6151C7.51862 13.541 6.2491 14.3298 4.66466 14.9813C4.39403 13.3794 4.73355 11.5569 5.75212 10.337ZM17.7929 9.93043C19.2395 8.42152 21.1192 8.21576 22.0246 9.0731C23.112 10.0872 22.8611 12.0272 21.3013 13.737C19.9875 15.1577 18.3144 16.1963 16.4545 16.9606C15.5245 14.6629 16.1592 11.5961 17.7879 9.92553L17.7929 9.93043Z'
                fill='white'
            />
        </svg>
    )
}
