import React, { useEffect } from 'react'
import { isEmpty } from '@dostavkee/helpers'
import { useInput } from '@dostavkee/react-hooks'
import clsx from 'clsx'

import { InputMessage } from '../input-message'
import styles from './textarea.module.scss'

export interface TextAreaProps
    extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value'> {
    isInvalid?: boolean
    errorMessage?: string
    label?: string
    value?: string | null
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ isInvalid, errorMessage, value = '', label, ...props }, ref) => {
        const { handleBlur, handleFocus, isFocused, inputRef } = useInput<HTMLTextAreaElement>({
            ...props,
            ref,
        })

        /**
         * Auto fit height of textarea when value changes
         */
        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.style.height = '0px'
                const { scrollHeight } = inputRef.current

                inputRef.current.style.height = `${scrollHeight}px`
            }
        }, [inputRef, value])

        return (
            <div className={styles['textarea-wrapper']}>
                <div
                    className={clsx(styles['textarea-block'], {
                        [styles['textarea-block--invalid']]: isInvalid,
                        [styles['textarea-block--focused']]: isFocused,
                        [styles['textarea-block--disabled']]: Boolean(props.disabled),
                    })}
                    onClick={() => inputRef.current?.focus()}
                >
                    <textarea
                        ref={inputRef}
                        aria-disabled={props.disabled}
                        className={clsx(styles['textarea'], {
                            [styles['no-label']]: !label,
                        })}
                        {...props}
                        value={value ?? ''}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                    {label && (
                        <label
                            htmlFor={props?.id}
                            className={clsx(styles['label'], {
                                [styles['label--focused']]: isFocused || !isEmpty(value),
                            })}
                        >
                            {label}
                        </label>
                    )}
                </div>
                {errorMessage && <InputMessage variant='error'>{errorMessage}</InputMessage>}
            </div>
        )
    }
)

TextArea.displayName = 'TextArea'
