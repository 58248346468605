import React, { forwardRef } from 'react'
import { clsx } from 'clsx'

import { Spinner } from '../spinner'
import styles from './button.module.scss'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode
    prefixEl?: React.ReactNode
    postfixEl?: React.ReactNode
    variant?:
        | 'primary'
        | 'primary-root'
        | 'secondary'
        | 'error'
        | 'ghost'
        | 'outline'
        | 'outline-primary'
        | 'outline-white'
    size?: '2xs' | 'xs' | 'small' | 'medium' | 'large'
    color?: 'primary' | 'error' | 'success' | 'black' | 'white' | 'brand' | 'link' | 'accent'
    fullWidth?: boolean
    className?: string
    isRounded?: boolean
    isLoading?: boolean
    isText?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            fullWidth,
            prefixEl,
            postfixEl,
            variant = 'primary',
            size = 'medium',
            className = '',
            isRounded,
            isLoading,
            isText,
            color,
            ...props
        },
        ref
    ) => (
        <button
            ref={ref}
            aria-disabled={props.disabled}
            aria-label={children ? String(children) : undefined}
            type='button'
            className={clsx(styles.button, className, {
                [styles[`button--variant-${variant}`]]: true,
                [styles[`button--color-${color}`]]: true,
                [styles[`button--size-${size}`]]: true,
                [styles['button--full-width']]: fullWidth,
                [styles['button--rounded']]: isRounded,
                [styles['button--with-icon']]: prefixEl || postfixEl,
                [styles['button--no-children']]: !children,
                [styles['button--text']]: isText,
            })}
            {...props}
            disabled={isLoading || props.disabled}
        >
            <span style={props.style}>
                {!isLoading && prefixEl && prefixEl}
                {isLoading ? (
                    <Spinner
                        size={size === 'xs' || size === 'small' ? 'small' : 'medium'}
                        color={
                            ['primary', 'error', 'primary-root'].includes(variant)
                                ? 'white'
                                : 'default'
                        }
                    />
                ) : (
                    children
                )}
                {!isLoading && postfixEl && postfixEl}
            </span>
        </button>
    )
)

Button.displayName = 'Button'
