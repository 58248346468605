import React, { FC, memo, useEffect } from 'react'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Button, Modal } from '@dostavkee/web-ui'

import { usePayment } from '@/dostavkee/entities/balance'
import { PAYMENT_STATUS } from '@/shell/shared/config'

import { PaymentFormSelect } from './payment-form-select/payment-form-select'
import { PaymentFormSelectedCard } from './payment-form-selected/payment-form-selected-card'
import { PaymentFormSelectedInvoice } from './payment-form-selected/payment-form-selected-invoice'
import { PaymentFormSelectedSBP } from './payment-form-selected/payment-form-selected-sbp'
import { PaymentFormStatus } from './payment-form-status/payment-form-status'

export interface PaymentFormModalProps {
    status?: PAYMENT_STATUS
}

export const PaymentFormModal: FC<PaymentFormModalProps> = memo(
    ({ status }: PaymentFormModalProps) => {
        const { isMobile } = useDeviceDetection()
        const {
            handleOpenPaymentModal,
            isPaymentModalOpen,
            handleClosePaymentModal,
            handleSubmitPayment,
            paymentFormValues,
            resetPaymentFormStates,
            paymentResponse,
            paymentInvoiceResponse,
            paymentStatus,
            setPaymentStatus,
        } = usePayment()

        useEffect(() => {
            if (status && ['success', 'fail'].includes(status) && !paymentStatus) {
                setPaymentStatus(status)
                handleOpenPaymentModal()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [status])

        return (
            <Modal isOpen={isPaymentModalOpen} onClose={handleClosePaymentModal}>
                <Modal.Header onClose={handleClosePaymentModal}>Пополнение баланса</Modal.Header>
                {!paymentStatus && !paymentFormValues?.requested && (
                    <Modal.Body>
                        <PaymentFormSelect
                            initialValues={paymentFormValues}
                            onSubmit={handleSubmitPayment}
                        />
                    </Modal.Body>
                )}

                {!paymentStatus &&
                    paymentFormValues?.requested &&
                    paymentFormValues?.paymentMethod === 'card' && (
                        <>
                            <Modal.Body>
                                <PaymentFormSelectedCard />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size='small'
                                    variant='secondary'
                                    onClick={resetPaymentFormStates}
                                >
                                    Назад
                                </Button>
                                {paymentResponse && (
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            window.location.href = paymentResponse.paymentUrl
                                        }}
                                    >
                                        Оплатить на сайте
                                    </Button>
                                )}
                            </Modal.Footer>
                        </>
                    )}
                {!paymentStatus &&
                    paymentFormValues?.requested &&
                    paymentFormValues?.paymentMethod === 'invoice' && (
                        <>
                            <Modal.Body>
                                <PaymentFormSelectedInvoice />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size='small'
                                    variant='secondary'
                                    onClick={resetPaymentFormStates}
                                >
                                    Назад
                                </Button>
                                {paymentInvoiceResponse && (
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            if (paymentInvoiceResponse.pdf_url) {
                                                window.open(
                                                    paymentInvoiceResponse.pdf_url,
                                                    '_blank',
                                                    'noopener'
                                                )
                                            }
                                        }}
                                    >
                                        Открыть счёт
                                    </Button>
                                )}
                            </Modal.Footer>
                        </>
                    )}
                {!paymentStatus &&
                    paymentFormValues?.requested &&
                    paymentFormValues?.paymentMethod === 'sbp' && (
                        <>
                            <Modal.Body>
                                <PaymentFormSelectedSBP />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size='small'
                                    variant='secondary'
                                    onClick={resetPaymentFormStates}
                                >
                                    Назад
                                </Button>

                                {isMobile && paymentResponse && (
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            window.location.href = paymentResponse.paymentUrl
                                        }}
                                    >
                                        Оплатить
                                    </Button>
                                )}
                            </Modal.Footer>
                        </>
                    )}
                {paymentStatus && (
                    <>
                        <Modal.Body>
                            <PaymentFormStatus paymentStatus={paymentStatus} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size='small' onClick={handleClosePaymentModal}>
                                Закрыть окно
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        )
    }
)

PaymentFormModal.displayName = 'PaymentFormModal'
