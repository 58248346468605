import React from 'react'
import { IconCheck } from '@dostavkee/web-ui'
import clsx from 'clsx'

import styles from './payment-form-status.module.scss'

export const PaymentFormStatusSuccess: React.FC = () => {
    return (
        <div className={styles['payment-form-status__content']}>
            <div
                className={clsx([
                    styles['payment-form-status__icon'],
                    styles['payment-form-status__icon--success'],
                ])}
            >
                <IconCheck color='var(--extensions-background-deep-success)' size={40} />
            </div>
            <div className={styles['payment-form-status__description']}>
                <h1 className={styles['payment-form-status__title']}>Баланс успешно пополнен</h1>
                <span>Отправили чек на почту</span>
            </div>
        </div>
    )
}
