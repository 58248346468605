import React from 'react'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { useAuth } from '@/shell/shared/model'

export const Route = createFileRoute('/_dashboard/dostavkee/')({
    component: DostavkeeGuard,
})

function DostavkeeGuard() {
    const { isDostavkeeEnabled } = useAuth()

    if (!isDostavkeeEnabled) {
        return <Navigate to='/dostavkee/city-not-available' />
    }

    return <Navigate to='/dostavkee/orders/active' />
}
