import React, { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './logo-root.module.scss'

export interface LogoRootProps extends HTMLAttributes<HTMLDivElement> {
    size?: number
    className?: string
}

export const LogoRoot: FC<LogoRootProps> = ({ size = 50, className = '', ...props }) => {
    return (
        <div className={clsx(styles['logo-root'], className)} {...props}>
            <svg
                fill='none'
                height={size}
                viewBox='0 0 51 51'
                width={size}
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M15.2374 0.818115H35.1068C43.4189 0.818115 50.1699 7.56915 50.1699 15.8812V35.7507C50.1699 44.0627 43.4189 50.8137 35.1068 50.8137H15.233C6.92535 50.8181 0.174316 44.0671 0.174316 35.7551V15.8812C0.174316 7.56915 6.92535 0.818115 15.2374 0.818115Z'
                    fill='#1D1D1B'
                />
                <path
                    d='M50.3921 35.3933V30.616C47.5236 33.271 44.006 35.1924 40.5761 35.7601C37.3831 36.2841 34.9269 35.8824 33.0672 34.878C36.8655 33.0701 40.2077 30.7383 42.5498 28.1269C47.0675 23.14 47.392 16.5112 43.3569 12.6946C39.6814 9.15747 32.8655 9.60289 28.5057 13.8212C23.5846 18.4064 22.0495 26.2754 24.3478 32.4238C19.1371 33.6728 13.9704 33.5068 11.1107 31.6204C14.6634 30.0134 17.5669 28.0396 19.4266 25.795C22.2951 22.3365 22.6109 16.6771 19.5494 13.7776C15.9967 10.4413 9.6633 11.4457 6.11061 15.83C3.61057 18.9217 2.23335 24.0308 3.40881 28.8081C2.35616 29.0789 1.26842 29.3147 0.189453 29.5243V35.0003C2.12808 34.6422 4.02286 34.1968 5.83868 33.6378C9.91769 38.3365 18.3126 39.7077 27.2689 37.0963C30.3742 40.3889 35.3392 42.1968 42.3656 40.878C45.085 40.3627 47.7429 39.2972 50.1991 37.7776C50.3219 37.0003 50.4096 36.2142 50.4096 35.402L50.3921 35.3933ZM10.4265 18.8867C11.9616 17.0003 14.4616 16.4762 15.795 17.6815C16.9266 18.7645 16.6459 20.8955 14.9441 22.9479C13.5756 24.5985 11.3124 26.0046 8.48783 27.1662C8.00537 24.3103 8.61065 21.0614 10.4265 18.8867ZM31.8918 18.1618C34.4707 15.4719 37.8217 15.1051 39.4357 16.6335C41.3744 18.4413 40.927 21.8998 38.1463 24.9479C35.8041 27.4806 32.8216 29.3321 29.5057 30.6946C27.8478 26.5985 28.9794 21.1313 31.883 18.1531L31.8918 18.1618Z'
                    fill='white'
                />
            </svg>
        </div>
    )
}

LogoRoot.displayName = 'LogoRoot'
