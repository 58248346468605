import { GetOrdersQueryParamsType, ServiceOrder } from '@dostavkee/contracts-console/entities'
import {
    getOrdersPublicIdQueryKey,
    getOrdersQueryKey,
    getUsersProfileBalanceQueryKey,
    usePostOrdersOrderIdCancel,
} from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

interface UseOrderCancelProps {
    order: ServiceOrder
}

export const useOrderCancel = ({ order }: UseOrderCancelProps) => {
    const queryClient = useQueryClient()

    const { mutate, isPending } = usePostOrdersOrderIdCancel({
        mutation: {
            onSuccess: async () => {
                /**
                 * Обновляем кэш заказа
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersPublicIdQueryKey(order.public_id.toString()),
                })

                /**
                 * Сбрасываем кэш списка активных заказов только в случае отмены заказа
                 * Получение новых данных после отмены заказа вызывает неправильное поведение уведомлений
                 * Выходит уведомление об успешном выполнении заказа, хотя заказ был отменён
                 *
                 * TODO: После перехода на метод /notifications нужно будет пересмотреть этот момент
                 */
                await queryClient.resetQueries({
                    queryKey: getOrdersQueryKey({ type: GetOrdersQueryParamsType.active }),
                })

                /**
                 * Обновляем баланс пользователя
                 */
                await queryClient.invalidateQueries({
                    queryKey: getUsersProfileBalanceQueryKey(),
                })

                toast.success(`Заказ №${order.public_id} отменён`)
            },
            onError: (error) => {
                Sentry.captureException(error)

                toast.error(
                    `Нам не удалось отменить заказ №${order.public_id}.
                    Пожалуйста, подождите несколько минут и повторите попытку.
                    Если ошибка повторится несколько раз, обратитесь в службу поддержки.`
                )
            },
        },
    })

    return {
        mutate,
        isPending,
    }
}
