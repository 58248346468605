import React, { FC, memo } from 'react'
import { Burger, Button, IconChevronLeft, IconFilter, IconSearch } from '@dostavkee/web-ui'
import { useRouterState } from '@tanstack/react-router'
import clsx from 'clsx'
import { useAtom } from 'jotai'

import { isOpenRideOrdersFilter } from '@/corpdrivee/entities/ride-order'
import { useIsOpenEmployeeSearch } from '@/corpdrivee/pages/employees-page'

import { useNavigateBack, useSidebar } from '../../model'
import styles from './header-mobile.module.scss'

interface HeaderMobileProps {
    params: Record<string, string>
}

const titles = {
    '/dostavkee/orders/active': 'Заказы',
    '/dostavkee/orders/archive': 'Заказы',
    '/dostavkee/orders/create': 'Создать заказ',
    '/dostavkee/orders/create/address': 'Создать заказ',
    '/dostavkee/addresses': 'Адреса',
    '/dostavkee/profile': 'Профиль',
    '/dostavkee/balance': 'Баланс',
    '/dostavkee/profile/transactions': 'История транзакций',
    '/dostavkee/supports': 'Помощь',
    '/dostavkee/documents': 'Документы',

    '/corpdrivee/ride-orders': 'Поездки',
    '/corpdrivee/employees': 'Сотрудники',
    '/corpdrivee/documents': 'Документы',
    '/corpdrivee/profile': 'Профиль',
    '/corpdrivee/balance': 'Баланс',
    '/corpdrivee/supports': 'Помощь',
}

export const HeaderMobile: FC<HeaderMobileProps> = memo(({ params }: HeaderMobileProps) => {
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })

    const isOrdersPage = ['/dostavkee/orders/active', '/dostavkee/orders/archive'].includes(
        pathname
    )
    const isCreateOrderPage = pathname.startsWith('/dostavkee/orders/create') && !isOrdersPage
    const isOrderPage =
        pathname.startsWith('/dostavkee/orders') && !isOrdersPage && !isCreateOrderPage

    const isRideOrdersPage = pathname === '/corpdrivee/ride-orders'

    // TODO: Use custom hook instead useAtom
    const [_, setIsOpenRideOrdersFilterBottomSheet] = useAtom(isOpenRideOrdersFilter)

    const { setIsOpen: setIsOpenEmployeeSearch } = useIsOpenEmployeeSearch()

    const isRideOrderPage = pathname.startsWith('/corpdrivee/ride-orders') && !isRideOrdersPage

    const isEmployeesPage = pathname.startsWith('/corpdrivee/employees')

    if (isRideOrdersPage) {
        return (
            <HeaderMobileContent
                prefixElement={<SidebarBurger />}
                title={titles[pathname as keyof typeof titles] ?? ''}
                postfixElement={
                    <Button
                        isText
                        variant='ghost'
                        onClick={() => setIsOpenRideOrdersFilterBottomSheet(true)}
                    >
                        <IconFilter />
                    </Button>
                }
            />
        )
    }

    if (isRideOrderPage) {
        return (
            <HeaderMobileContent
                prefixElement={<BackButton fallback={'/corpdrivee/ride-orders'} />}
                title={`Заказ №${params?.id}`}
            />
        )
    }

    if (isEmployeesPage) {
        return (
            <HeaderMobileContent
                prefixElement={<SidebarBurger />}
                title={titles[pathname as keyof typeof titles] ?? ''}
                postfixElement={
                    <Button isText variant='ghost' onClick={() => setIsOpenEmployeeSearch(true)}>
                        <IconSearch />
                    </Button>
                }
            />
        )
    }

    if (isCreateOrderPage) {
        return (
            <HeaderMobileContent
                prefixElement={<BackButton fallback={'/dostavkee/orders/active'} />}
                title={titles[pathname as keyof typeof titles] ?? ''}
            />
        )
    }

    if (isOrderPage) {
        return (
            <HeaderMobileContent
                prefixElement={<BackButton fallback={'/dostavkee/orders/active'} />}
                title={`Заказ №${params.public_id}`}
            />
        )
    }

    return (
        <HeaderMobileContent
            isNoBorder={isOrdersPage}
            prefixElement={<SidebarBurger />}
            title={titles[pathname as keyof typeof titles] ?? ''}
        />
    )
})

interface HeaderMobileContentProps {
    prefixElement?: React.ReactNode
    postfixElement?: React.ReactNode
    title: string
    isNoBorder?: boolean
}

export const HeaderMobileContent: React.FC<HeaderMobileContentProps> = ({
    prefixElement,
    postfixElement,
    title,
    isNoBorder,
}) => {
    const isLoadingRoute = useRouterState({
        select: (state) => state.isLoading,
    })

    return (
        <header
            className={clsx({
                [styles['header-mobile']]: true,
                [styles['header-mobile--no-border']]: isNoBorder || isLoadingRoute,
            })}
        >
            <div style={{ width: 32 }}>{prefixElement}</div>
            <h1 className={styles['header-mobile__title']}>{title}</h1>
            <div style={{ width: 32 }}>{postfixElement}</div>
        </header>
    )
}

export const BackButton = ({ fallback }: { fallback: string }) => {
    const navigateBack = useNavigateBack({ fallback: fallback })

    return (
        <Button
            isText
            className={styles['order-header-mobile__back-btn']}
            variant='ghost'
            onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                navigateBack()
            }}
        >
            <IconChevronLeft />
        </Button>
    )
}

const SidebarBurger = () => {
    const { isSidebarOpen, setIsSidebarOpen } = useSidebar()

    return <Burger isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(true)} />
}

HeaderMobile.displayName = 'HeaderMobile'
