import React, { FC } from 'react'
import { phoneFormatter } from '@dostavkee/helpers'
import { ReactNode } from '@tanstack/react-router'
import clsx from 'clsx'

import styles from './addresses-points.module.scss'

export interface AddressPoint {
    address_name: string
    phone?: string
    comment?: string
}

export interface AddressesPointsProps {
    points: AddressPoint[]
    isAddressBold?: boolean
    className?: string
    headerLeftSlot?: ReactNode
    headerRightSlot?: ReactNode
    size?: 's' | 'm'
}

export const AddressesPoints: FC<AddressesPointsProps> = ({
    points,
    isAddressBold = false,
    className,
    headerRightSlot,
    headerLeftSlot,
    size = 's',
}) => {
    return (
        <section className={clsx([styles['addresses-points'], className && className])}>
            {(headerLeftSlot || headerRightSlot) && (
                <header className={styles['addresses-points__header']}>
                    {headerLeftSlot && <div>{headerLeftSlot}</div>}
                    {headerRightSlot && <div>{headerRightSlot}</div>}
                </header>
            )}
            {points.map((point, index) => (
                <div
                    key={index}
                    className={clsx({
                        [styles['item']]: true,
                        [styles[`item--size-${size}`]]: true,
                    })}
                >
                    <span
                        className={clsx({
                            [styles['item__address-bold']]: isAddressBold,
                        })}
                    >
                        {point.address_name}
                        {/* {'entry' in point
                            ? addressFormatter(
                                  point.address_name,
                                  point.entry,
                                  point.floor,
                                  point.room
                              )
                            : point.address_name} */}
                    </span>

                    {point.phone && (
                        <a className={styles['item__phone']} href={`tel:+${point.phone}`}>
                            {phoneFormatter(point.phone)}
                        </a>
                    )}

                    {point.comment && (
                        <span className={styles['item__comment']}>{point.comment}</span>
                    )}
                </div>
            ))}
        </section>
    )
}

AddressesPoints.displayName = 'AddressesPoints'
