import { atom, useAtom } from 'jotai'

const isSidebarOpenAtom = atom<boolean>(false)

export const useSidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useAtom(isSidebarOpenAtom)

    // debugger

    return { isSidebarOpen, setIsSidebarOpen }
}
