import React, { FC, forwardRef, useEffect, useMemo, useState } from 'react'
import { phoneFormatter } from '@dostavkee/helpers'
import { MaskEventDetail, useMask } from '@react-input/mask'

import { Input, InputProps } from '../input/input'

export interface InputPhoneProps extends Omit<InputProps, 'onChange'> {
    onChange: (value: string) => void
}

export const InputPhone: FC<InputPhoneProps> = forwardRef<HTMLInputElement, InputPhoneProps>(
    ({ onChange, ...props }, ref) => {
        const [detail, setDetail] = useState<MaskEventDetail | null>(null)

        const maskRef = useMask({
            mask: '+_ (___) ___-__-__',
            replacement: { _: /\d/ },
            showMask: false,
            /**
             * Tracks changes to the input value and formats it according to the mask.
             * @returns The formatted input value.
             */
            track: ({ data, inputType, selectionStart }) => {
                if (!data) {
                    return
                }

                /**
                 * If the user is trying to insert a value at the start of the input
                 * and the input value is empty, we'll add a '7' prefix automatically.
                 */
                if (inputType === 'insert' && selectionStart === 0 && data.length === 1) {
                    if (data === '7' || data === '8') {
                        return '7'
                    }

                    return `7${data}`
                }

                /**
                 * If the user is trying to insert a value at the start of the input
                 * and the input value is not empty, we'll check whether the input
                 * value is a valid phone number prefix + digits. If so, we'll return
                 * the input value with the '7' prefix removed and the new value inserted.
                 */
                const isInsertionAtStart = inputType === 'insert' && selectionStart === 0
                const isExpectedLength = data.length > 10
                const isValidPrefix =
                    data.startsWith('7') || data.startsWith('8') || data.startsWith('+7')

                if (isInsertionAtStart && isExpectedLength && isValidPrefix) {
                    return data.replace(/^8/, '7')
                }

                // Otherwise, we'll just return the input value as-is.
                return isInsertionAtStart ? `7${data}` : data
            },
            onMask: (event) => {
                setDetail(event.detail)
                onChange(event.detail.input)
            },
        })

        const value = useMemo(() => {
            if (detail?.value) {
                return detail.value
            }

            if (props.value) {
                return phoneFormatter(props.value as string)
            }

            return props.value
        }, [detail?.value, props.value])

        useEffect(() => {
            if (!ref) {
                return
            }

            if (typeof ref === 'function') {
                ref(maskRef.current)
            } else {
                ref.current = maskRef.current
            }
        }, [maskRef, ref])

        return (
            <Input
                ref={maskRef}
                autoComplete='off'
                inputMode='tel'
                type='text'
                {...props}
                value={value}
                onChange={() => {}}
            />
        )
    }
)
InputPhone.displayName = 'InputPhone'
