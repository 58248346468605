import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/corpdrivee/_sidebar-layout/employees')({
    onEnter: () => {
        document.title = 'CorpDrivee - Сотрудники'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        search?: string
    } => {
        return {
            search: search.search as string,
        }
    },
})
